import React, { Component } from "react";
import { LoginLayout } from "./layouts";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import routes from "./routes";
import AdminRoutes from "./admin.routes";
import withTracker from "./withTracker";
import "bootstrap/dist/css/bootstrap.min.css";
import "./shards-dashboard/styles/shards-dashboards.1.1.0.min.css";
import Cookie from "js-cookie";
import Login from "./views/Login";
const axios = require("axios").default;
const URL = require("./ApiUrl");

export default class App extends Component {
  constructor(props) {
    super(props);

    this.verifyToken = this.verifyToken.bind(this);
    this.state = {
      token: "",
      userData: "",
      error: "",
      auth: false
    };
  }

  getToken = () => {
    const token = Cookie.get("jwtToken") ? Cookie.get("jwtToken") : null;
    const userData = Cookie.get("userData") ? Cookie.get("userData") : null;
    this.setState({
      token: token,
      userData: userData
    });
  };

  verifyToken = async () => {
    const token = Cookie.get("jwtToken") ? Cookie.get("jwtToken") : null;
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    let response = await axios
      .get(`${URL.BASE_URL}/auth/verify`, config)
      .catch(function(error) {
        console.log(error);
      });

    if (response) {
      if (response.data.verified && !response.data.error) {
        this.setState({
          auth: true,
          role: response.data.role
        });
      }
    }

    //console.log(response);
    console.log(this.state);
  };

  componentDidMount() {
    //this.getToken();
    this.verifyToken();
  }

  loginRoute = () => {
    return (
      <Router>
        <Switch>
          <Route
            path="/login"
            component={withTracker(props => {
              return (
                <LoginLayout {...props}>
                  <Login {...props} />
                </LoginLayout>
              );
            })}
          />
          <Route
            path="/"
            component={withTracker(props => {
              return (
                <LoginLayout {...props}>
                  <Login {...props} />
                </LoginLayout>
              );
            })}
          />
        </Switch>
      </Router>
    );
  };

  //Super Admin Routes
  protectedRoute = () => {
    return (
      <Router basename={process.env.REACT_APP_BASENAME || ""}>
        <div>
          {routes.map((route, index) => {
            return (
              <Route
                key={index}
                path={route.path}
                exact={route.exact}
                component={withTracker(props => {
                  return (
                    <route.layout {...props}>
                      <route.component {...props} />
                    </route.layout>
                  );
                })}
              />
            );
          })}
        </div>
      </Router>
    );
  };

  CoordinatorRoute = () => {
    return (
      <Router basename={process.env.REACT_APP_BASENAME || ""}>
        <div>
          {AdminRoutes.map((route, index) => {
            return (
              <Route
                key={index}
                path={route.path}
                exact={route.exact}
                component={withTracker(props => {
                  return (
                    <route.layout {...props}>
                      <route.component {...props} />
                    </route.layout>
                  );
                })}
              />
            );
          })}
        </div>
        {/* <div>{alert("Coodniator Case Submission Closed")}</div> */}
      </Router>
    );
  };

  adminRoute = () => {
    return (
      <Router basename={process.env.REACT_APP_BASENAME || ""}>
        <div>
          {AdminRoutes.map((route, index) => {
            return (
              <Route
                key={index}
                path={route.path}
                exact={route.exact}
                component={withTracker(props => {
                  return (
                    <route.layout {...props}>
                      <route.component {...props} />
                    </route.layout>
                  );
                })}
              />
            );
          })}
        </div>
      </Router>
    );
  };

  render() {
    if (this.state.auth) {
      if (this.state.role === "super_admin") {
        return this.protectedRoute();
      } else if (this.state.role === "admin") {
        return this.adminRoute();
      } else {
        return this.CoordinatorRoute();
        // return alert("Coodniator Case Submission Closed")
      }
    } else {
      return this.loginRoute();
    }
  }
}
