import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  FormInput,
  Alert,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Button,
  Modal,
  ModalBody,
  ModalHeader
} from "shards-react";

import PageTitle from "../components/common/PageTitle";
import Cookie from "js-cookie";
const axios = require("axios").default;
const URL = require("../ApiUrl");

export default class AddFormData extends Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.getSingleForm = this.getSingleForm.bind(this);
    this.sendData = this.sendData.bind(this);
    this.alert = this.alert.bind(this);

    this.state = {
      status: false,
      msg: "",
      validation: false
    };
  }

  async getSingleForm() {
    const token = Cookie.get("jwtToken") ? Cookie.get("jwtToken") : null;
    let id = this.props.match.params.id;
    let id2 = this.props.match.params.id2;

    // let response2 = await axios({
    //   method: "get",
    //   url: `${URL.BASE_URL}/forms/singledata/${id}`,

    //   headers: { Authorization: `Bearer ${token}` }
    // });
    // console.log(response2.data.data, "✨⚡🚩🚀");

    this.setState({
      formId: id,
      patient_id_793: id2
    });
    let type = this.props.match.params.no;

    if (type === "edit") {
      let response3 = await axios({
        method: "get",
        url: `${URL.BASE_URL}/forms/singlefollowdata/${id}`,

        headers: { Authorization: `Bearer ${token}` }
      });
      console.log("🔥🚩🦚", response3.data);

      this.setState(response3.data.data.fields);
      this.setState({
        id: response3.data.data._id,
        // patientId: response3.data.data.fields.
      });
    }

    // console.log(this.state.fieldValue);
    // console.log(response2.data.data);
  }

  handleChange(e) {
    console.log(this.state);
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  async sendData(e) {
    e.preventDefault();

    //Validation
    let msg = "";
    if (!this.state.alive_dead) {
      msg = "Alive / Dead Can't be empty";
      this.setState({
        validation: true,
        msg: msg
      });
      return 1;
    } else if (this.state.alive_dead === "Alive" && !this.state.reHosp) {
      msg = "Re-hospitalization Can't be empty";
      this.setState({
        validation: true,
        msg: msg
      });
      return 1;
    } else if (this.state.alive_dead === "Alive" && !this.state.unPlanRev) {
      msg = "Unplanned Revascularization Can't be empty";
      this.setState({
        validation: true,
        msg: msg
      });
      return 1;
    } else if (
      this.state.alive_dead === "Alive" &&
      this.state.reHosp === "yes" &&
      !this.state.if_yes_reson
    ) {
      msg = "Reason for Re-hospitalization Can't be empty";
      this.setState({
        validation: true,
        msg: msg
      });
      return 1;
    } else if (this.state.alive_dead === "Dead" && !this.state.causeOfDeath) {
      msg = "Cause Of Death Can't be empty";
      this.setState({
        validation: true,
        msg: msg
      });
      return 1;
    }

    const token = Cookie.get("jwtToken") ? Cookie.get("jwtToken") : null;
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };

    let stateData = Object.assign({}, this.state);

    //delete stateData.formId;
    delete stateData.name;
    delete stateData.ipNo;
    delete stateData.status;

    let followUpData = {
      formId: stateData.formId,
      alive_dead: stateData.alive_dead,
      if_yes_reson: stateData.if_yes_reson,
      lvef: stateData.lvef,
      lvla: stateData.lvla,
      mr: stateData.mr,
      reHosp: stateData.reHosp,
      unPlanRev: stateData.unPlanRev,
      causeOfDeath: stateData.causeOfDeath
    }; // this.state;
    // console.log(followUpData);
    // console.log("Sening");
    let type = this.props.match.params.no;

    let response;

    if (type === "edit") {
      followUpData.id = stateData.id;
      response = await axios
        .patch(`${URL.BASE_URL}/forms/updatefollowupdata`, followUpData, config)
        .catch(function(error) {
          console.log(error);
          return;
        });
    } else {
      followUpData.patient_id_793 = this.state.patient_id_793;
      response = await axios
        .post(`${URL.BASE_URL}/forms/addfollowupdata`, followUpData, config)
        .catch(function(error) {
          console.log(error);
          return;
        });
    }

    if (response) {
      if (response.data.statusCode === 200) {
        this.setState({
          status: true,
          isCompleted: true
        });
      } else {
        this.setState({
          status: false
        });
      }
    }

    setTimeout(() => {
      this.setState({
        status: false
      });
    }, 2000);

    console.log(response);
  }

  alert() {
    if (this.state.status) {
      return (
        <Alert className="mb-0">
          <i className="fa fa-info mx-2"></i> Successfully added fields
        </Alert>
      );
    } else {
      return <div></div>;
    }
  }

  validation = () => {
    if (this.state.validation) {
      return (
        <Alert theme="warning" className="mb-0">
          <i className="fa fa-info mx-2"></i> {this.state.msg}
        </Alert>
      );
    } else {
      return <div></div>;
    }
  };

  AddNewField = () => {
    let alive = this.state.alive_dead;
    let lvla = this.state.lvla;
    let mr = this.state.mr;
    let unPlanRev = this.state.unPlanRev;

    return (
      <>
        <form>
          <this.alert />
          <this.validation />
          <Container fluid className="main-content-container px-4 pb-4">
            {/* Page Header */} <h2>30 Days Followup details</h2>
            <hr />
            <Row>
              <Col md="4">
                Alive / Dead ?<strong style={{ color: "red" }}> *</strong> :
              </Col>
              <FormGroup>
                <input
                  type="radio"
                  id="radio20"
                  name="alive_dead"
                  value="Alive"
                  onChange={this.handleChange}
                  checked={alive === "Alive"}
                  style={{
                    marginTop: "2px",
                    marginRight: "10px",
                    fontSize: "16px",
                    border: "0px",
                    width: "20px",
                    height: "20px"
                  }}
                ></input>
                Alive
                <input
                  type="radio"
                  id="radio21"
                  name="alive_dead"
                  checked={alive === "Dead"}
                  value="Dead"
                  onChange={this.handleChange}
                  style={{
                    marginTop: "2px",
                    marginLeft: "20px",
                    marginRight: "10px",
                    fontSize: "16px",
                    border: "0px",
                    width: "20px",
                    height: "20px"
                  }}
                ></input>
                Dead
                <input
                  type="radio"
                  id="radio2"
                  checked={alive === "Lost for follow up"}
                  name="alive_dead"
                  value="Lost for follow up"
                  onChange={this.handleChange}
                  style={{
                    marginTop: "2px",
                    marginLeft: "20px",
                    marginRight: "10px",
                    fontSize: "16px",
                    border: "0px",
                    width: "20px",
                    height: "20px"
                  }}
                ></input>
                Lost for follow up
              </FormGroup>
            </Row>
            {this.state.alive_dead === "Lost for follow up" ? (
              ""
            ) : (
              <div>
                <hr />
                <this.firstCondition />
                <this.secondCondition />
                <Row>
                  <Col md="4">
                    Unplanned Revascularization{" "}
                    <strong style={{ color: "red" }}> *</strong> :
                  </Col>
                  <FormGroup>
                    <input
                      type="radio"
                      id="radio25"
                      name="unPlanRev"
                      value="yes"
                      onChange={this.handleChange}
                      checked={unPlanRev === "yes"}
                      style={{
                        marginTop: "2px",
                        marginRight: "10px",
                        fontSize: "16px",
                        border: "0px",
                        width: "20px",
                        height: "20px"
                      }}
                    ></input>
                    Yes
                    <input
                      type="radio"
                      id="radio26"
                      name="unPlanRev"
                      checked={unPlanRev === "no"}
                      value="no"
                      onChange={this.handleChange}
                      style={{
                        marginTop: "2px",
                        marginLeft: "20px",
                        marginRight: "10px",
                        fontSize: "16px",
                        border: "0px",
                        width: "20px",
                        height: "20px"
                      }}
                    ></input>
                    No
                  </FormGroup>
                </Row>
                <hr />
                <Row>
                  {/* Page Header */} <h4>ECHO 30 Days</h4>
                </Row>
                <hr />
                <Row>
                  <Col md="4">LV/LA Thrombus :</Col>
                  <FormGroup>
                    <input
                      type="radio"
                      id="radio25"
                      name="lvla"
                      value="Yes"
                      onChange={this.handleChange}
                      checked={lvla === "Yes"}
                      style={{
                        marginTop: "2px",
                        marginRight: "10px",
                        fontSize: "16px",
                        border: "0px",
                        width: "20px",
                        height: "20px"
                      }}
                    ></input>
                    Yes
                    <input
                      type="radio"
                      id="radio26"
                      name="lvla"
                      checked={lvla === "no"}
                      value="no"
                      onChange={this.handleChange}
                      style={{
                        marginTop: "2px",
                        marginLeft: "20px",
                        marginRight: "10px",
                        fontSize: "16px",
                        border: "0px",
                        width: "20px",
                        height: "20px"
                      }}
                    ></input>
                    No
                  </FormGroup>
                </Row>
                <hr />
                <Row>
                  <FormGroup>
                    <Col md="12" sm="12">
                      <FormGroup>
                        <InputGroup className="mb-3">
                          <InputGroupAddon type="prepend">
                            <InputGroupText
                              style={{
                                color: "#99a",
                                "font-size": "14px",
                                "font-weight": "500"
                              }}
                            >
                              LVEF(%)
                            </InputGroupText>
                          </InputGroupAddon>
                          <FormInput
                            type="number"
                            onChange={this.handleChange}
                            value={this.state.lvef}
                            name="lvef"
                            require
                          ></FormInput>
                        </InputGroup>
                      </FormGroup>
                    </Col>
                  </FormGroup>
                </Row>
                <hr />
                <Row>
                  <Col md="4">MR :</Col>
                  <FormGroup>
                    <input
                      type="radio"
                      id="radio25"
                      name="mr"
                      value="1"
                      onChange={this.handleChange}
                      checked={mr === "1"}
                      style={{
                        marginTop: "2px",
                        marginRight: "10px",
                        fontSize: "16px",
                        border: "0px",
                        width: "20px",
                        height: "20px"
                      }}
                    ></input>
                    1
                    <input
                      type="radio"
                      id="radio26"
                      name="mr"
                      checked={mr === "2"}
                      value="2"
                      onChange={this.handleChange}
                      style={{
                        marginTop: "2px",
                        marginLeft: "20px",
                        marginRight: "10px",
                        fontSize: "16px",
                        border: "0px",
                        width: "20px",
                        height: "20px"
                      }}
                    ></input>
                    2
                    <input
                      type="radio"
                      id="radio26"
                      name="mr"
                      checked={mr === "3"}
                      value="3"
                      onChange={this.handleChange}
                      style={{
                        marginTop: "2px",
                        marginLeft: "20px",
                        marginRight: "10px",
                        fontSize: "16px",
                        border: "0px",
                        width: "20px",
                        height: "20px"
                      }}
                    ></input>
                    3
                    <input
                      type="radio"
                      id="radio26"
                      name="mr"
                      checked={mr === "4"}
                      value="4"
                      onChange={this.handleChange}
                      style={{
                        marginTop: "2px",
                        marginLeft: "20px",
                        marginRight: "10px",
                        fontSize: "16px",
                        border: "0px",
                        width: "20px",
                        height: "20px"
                      }}
                    ></input>
                    4
                    <input
                      type="radio"
                      id="radio26"
                      name="mr"
                      checked={mr === "0"}
                      value="0"
                      onChange={this.handleChange}
                      style={{
                        marginTop: "2px",
                        marginLeft: "20px",
                        marginRight: "10px",
                        fontSize: "16px",
                        border: "0px",
                        width: "20px",
                        height: "20px"
                      }}
                    ></input>
                    0
                  </FormGroup>
                </Row>
                <hr />
              </div>
            )}
            <Row>
              <Col>
                <Button
                  size="sm"
                  theme="success"
                  className="mb-2 mr-1"
                  onClick={this.sendData}
                >
                  Submit
                </Button>
              </Col>
            </Row>
          </Container>
        </form>
      </>
    );
  };

  firstCondition = () => {
    let reHosp = this.state.reHosp;
    let alive = this.state.alive_dead;

    if (alive === "Alive") {
      return (
        <>
          <Row>
            <Col md="4">
              Re-hospitalization ? <strong style={{ color: "red" }}> *</strong>{" "}
              :
            </Col>
            <FormGroup>
              <input
                type="radio"
                id="radio25"
                name="reHosp"
                value="yes"
                onChange={this.handleChange}
                checked={reHosp === "yes"}
                style={{
                  marginTop: "2px",
                  marginRight: "10px",
                  fontSize: "16px",
                  border: "0px",
                  width: "20px",
                  height: "20px"
                }}
              ></input>
              Yes
              <input
                type="radio"
                id="radio26"
                name="reHosp"
                checked={reHosp === "no"}
                value="no"
                onChange={this.handleChange}
                style={{
                  marginTop: "2px",
                  marginLeft: "20px",
                  marginRight: "10px",
                  fontSize: "16px",
                  border: "0px",
                  width: "20px",
                  height: "20px"
                }}
              ></input>
              No
            </FormGroup>
          </Row>
          <hr />

          <this.thirdCondition />

          <hr />
        </>
      );
    } else {
      return <></>;
    }
  };

  secondCondition = () => {
    let causeOfDeath = this.state.causeOfDeath;
    let alive = this.state.alive_dead;

    if (alive === "Dead") {
      return (
        <>
          <Row>
            <Col md="4">
              Cause of death <strong style={{ color: "red" }}> *</strong> :
            </Col>
            <FormGroup>
              <input
                type="radio"
                id="radio25"
                name="causeOfDeath"
                value="Stroke"
                onChange={this.handleChange}
                checked={causeOfDeath == "Stroke"}
                style={{
                  marginTop: "2px",
                  marginRight: "10px",
                  fontSize: "16px",
                  border: "0px",
                  width: "20px",
                  height: "20px"
                }}
              ></input>
              Stroke
              <input
                type="radio"
                id="radio26"
                name="causeOfDeath"
                checked={causeOfDeath == "Re-infarction"}
                value="Re-infarction"
                onChange={this.handleChange}
                style={{
                  marginTop: "2px",
                  marginLeft: "20px",
                  marginRight: "10px",
                  fontSize: "16px",
                  border: "0px",
                  width: "20px",
                  height: "20px"
                }}
              ></input>
              Re-infarction
              <input
                type="radio"
                id="radio26"
                name="causeOfDeath"
                checked={causeOfDeath == "Sudden cardiac death"}
                value="Sudden cardiac death"
                onChange={this.handleChange}
                style={{
                  marginTop: "2px",
                  marginLeft: "20px",
                  marginRight: "10px",
                  fontSize: "16px",
                  border: "0px",
                  width: "20px",
                  height: "20px"
                }}
              ></input>
              Sudden cardiac death
              <input
                type="radio"
                id="radio26"
                name="causeOfDeath"
                checked={causeOfDeath == "Major bleeding"}
                value="Major bleeding"
                onChange={this.handleChange}
                style={{
                  marginTop: "2px",
                  marginLeft: "20px",
                  marginRight: "10px",
                  fontSize: "16px",
                  border: "0px",
                  width: "20px",
                  height: "20px"
                }}
              ></input>
              Major bleeding
              <input
                type="radio"
                id="radio26"
                name="causeOfDeath"
                checked={causeOfDeath == "Others"}
                value="Others"
                onChange={this.handleChange}
                style={{
                  marginTop: "2px",
                  marginLeft: "20px",
                  marginRight: "10px",
                  fontSize: "16px",
                  border: "0px",
                  width: "20px",
                  height: "20px"
                }}
              ></input>
              Others
              <input
                type="radio"
                id="radio26"
                name="causeOfDeath"
                checked={causeOfDeath == "Unknown"}
                value="Unknown"
                onChange={this.handleChange}
                style={{
                  marginTop: "2px",
                  marginLeft: "20px",
                  marginRight: "10px",
                  fontSize: "16px",
                  border: "0px",
                  width: "20px",
                  height: "20px"
                }}
              ></input>
              Unknown
            </FormGroup>
          </Row>

          <hr />
        </>
      );
    } else {
      return <></>;
    }
  };

  thirdCondition = () => {
    let if_yes_reson = this.state.if_yes_reson;

    if (this.state.reHosp === "yes") {
      return (
        <>
          <Row>
            <Col md="4">
              If Yes, Reason <strong style={{ color: "red" }}> *</strong> :
            </Col>
            <FormGroup>
              <input
                type="radio"
                id="radio25"
                name="if_yes_reson"
                value="Reinfarction"
                onChange={this.handleChange}
                checked={if_yes_reson === "Reinfarction"}
                style={{
                  marginTop: "2px",
                  marginRight: "10px",
                  fontSize: "16px",
                  border: "0px",
                  width: "20px",
                  height: "20px"
                }}
              ></input>
              Reinfarction
              <input
                type="radio"
                id="radio26"
                name="if_yes_reson"
                checked={if_yes_reson === "ADHF"}
                value="ADHF"
                onChange={this.handleChange}
                style={{
                  marginTop: "2px",
                  marginLeft: "20px",
                  marginRight: "10px",
                  fontSize: "16px",
                  border: "0px",
                  width: "20px",
                  height: "20px"
                }}
              ></input>
              ADHF
              <input
                type="radio"
                id="radio26"
                name="if_yes_reson"
                checked={if_yes_reson === "Arrhythmia"}
                value="Arrhythmia"
                onChange={this.handleChange}
                style={{
                  marginTop: "2px",
                  marginLeft: "20px",
                  marginRight: "10px",
                  fontSize: "16px",
                  border: "0px",
                  width: "20px",
                  height: "20px"
                }}
              ></input>
              Arrhythmia
              <input
                type="radio"
                id="radio26"
                name="if_yes_reson"
                checked={if_yes_reson === "CVA"}
                value="CVA"
                onChange={this.handleChange}
                style={{
                  marginTop: "2px",
                  marginLeft: "20px",
                  marginRight: "10px",
                  fontSize: "16px",
                  border: "0px",
                  width: "20px",
                  height: "20px"
                }}
              ></input>
              CVA
              <input
                type="radio"
                id="radio26"
                name="if_yes_reson"
                checked={if_yes_reson === "Noncardiac"}
                value="Noncardiac"
                onChange={this.handleChange}
                style={{
                  marginTop: "2px",
                  marginLeft: "20px",
                  marginRight: "10px",
                  fontSize: "16px",
                  border: "0px",
                  width: "20px",
                  height: "20px"
                }}
              ></input>
              Noncardiac
              <input
                type="radio"
                id="radio26"
                name="if_yes_reson"
                checked={if_yes_reson === "Pulmonary embolism"}
                value="Pulmonary embolism"
                onChange={this.handleChange}
                style={{
                  marginTop: "2px",
                  marginLeft: "20px",
                  marginRight: "10px",
                  fontSize: "16px",
                  border: "0px",
                  width: "20px",
                  height: "20px"
                }}
              ></input>
              Pulmonary embolism
              <input
                type="radio"
                id="radio26"
                name="if_yes_reson"
                checked={if_yes_reson === "Resuscitated cardiac arrest"}
                value="Resuscitated cardiac arrest"
                onChange={this.handleChange}
                style={{
                  marginTop: "2px",
                  marginLeft: "20px",
                  marginRight: "10px",
                  fontSize: "16px",
                  border: "0px",
                  width: "20px",
                  height: "20px"
                }}
              ></input>
              Resuscitated cardiac arrest
              <input
                type="radio"
                id="radio26"
                name="if_yes_reson"
                checked={if_yes_reson === "VT/AVB"}
                value="VT/AVB"
                onChange={this.handleChange}
                style={{
                  marginTop: "2px",
                  marginLeft: "20px",
                  marginRight: "10px",
                  fontSize: "16px",
                  border: "0px",
                  width: "20px",
                  height: "20px"
                }}
              ></input>
              VT/AVB
            </FormGroup>
          </Row>
          <hr />
        </>
      );
    } else {
      return <></>;
    }
  };

  componentDidMount() {
    this.getSingleForm();
  }

  render() {
    if (this.state.isCompleted) {
      return (
        <div>
          <Modal size="lg" open={true}>
            <ModalHeader>Data Entered Successfully</ModalHeader>
            <ModalBody>
              <strong>Note</strong>
              <ol>
                <li>
                  {" "}
                  You will have the Edit option only for 7 Days for the
                  submitted data.
                </li>
              </ol>
              <Link to="/dashbord">Goto Dashboard</Link>
            </ModalBody>
          </Modal>
        </div>
      );
    } else return this.AddNewField();
  }
}
