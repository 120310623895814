export default function() {
  return [
    {
      title: "Dashboard",
      to: "/dashbord",
      htmlBefore: '<i class="material-icons">edit</i>',
      htmlAfter: ""
    },
    {
      title: "Demo Patients ",
      htmlBefore: '<i class="material-icons">healing</i>',
      to: "/demo"
    },

    // {
    //   title: "Add Patient data",
    //   htmlBefore: '<i class="material-icons">note_add</i>',
    //   to: "/add-formdata"
    // },
    // {
    //   title: "Patients Data - Pending",
    //   htmlBefore: '<i class="material-icons">healing</i>',
    //   to: "/incomplete"
    // },
    // {
    //   title: "Patients Data - 1st Submission ",
    //   htmlBefore: '<i class="material-icons">healing</i>',
    //   to: "/list-formdata"
    // },

    // {
    //   title: "Patients Data - Final w/ Follow Up ",
    //   htmlBefore: '<i class="material-icons">healing</i>',
    //   to: "/finished"
    // },
    {
      title: "Logout ",
      htmlBefore: '<i class="material-icons">&#xE879;</i>',
      to: "/logout"
    }
  ];
}
