import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  FormSelect,
  FormInput,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Button,
  Alert,
  Modal,
  ModalBody,
  ModalHeader
} from "shards-react";
import PageTitle from "../components/common/PageTitle";
import Cookie from "js-cookie";
import ReactLoading from "react-loading";
import { useMediaQuery } from "react-responsive";
const axios = require("axios").default;
const URL = require("../ApiUrl");

export default class AddFormData extends Component {
  constructor(props) {
    super(props);

    this.getFormFields = this.getFormFields.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCheck = this.handleCheck.bind(this);
    this.RenderFields = this.RenderFields.bind(this);
    this.firstSend = this.firstSend.bind(this);
    this.nextData = this.nextData.bind(this);
    this.prevData = this.prevData.bind(this);
    this.BottomButton = this.BottomButton.bind(this);
    this.TopTab = this.TopTab.bind(this);
    this.CustomRadio = this.CustomRadio.bind(this);
    this.CustomCheckBox = this.CustomCheckBox.bind(this);
    this.redirectState = this.redirectState.bind(this);
    this.Validation = this.Validation.bind(this);
    this.MandatoryField = this.MandatoryField.bind(this);
    this.FieldValueCheck = this.FieldValueCheck.bind(this);
    this.state = {
      formFields: [],
      fieldValue: {},
      checkBoxValue: [],
      sliceArray: [],
      start: 0,
      end: 1,
      id: null,
      selectedFile: false,
      isLoading: false,
      validation: false,
      isCompleted: false
    };
  }

  async getFormFields() {
    const token = Cookie.get("jwtToken") ? Cookie.get("jwtToken") : null;
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    let response = await axios
      .get(`${URL.BASE_URL}/fields/get`, config)
      .catch(error => {
        console.log(error);
        this.setState({
          error: error
        });
      });
    if (response) {
      let hospitalId = response.data.hospitalId;
      let count = Number(response.data.count) + 1;

      if (count < 10) {
        count = "000" + String(count);
      }
      if (count >= 10 && count < 100) {
        count = "00" + String(count);
      }
      if (count >= 100) {
        count = "0" + String(count);
      }

      let pId = hospitalId + String(count);

      this.setState({
        formFields: response.data.data,
        fieldValue: {
          patient_id_793: pId
        }
      });
      //console.log(response.data.data);

      let fields = [];
      let sliceNo = [];

      fields = response.data.data;

      fields.forEach((item, key) => {
        // console.log(key, item.fieldType);
        if (item.fieldType === "title") {
          sliceNo.push(key);
        }
      });
      sliceNo.push(fields.length);

      this.setState({
        sliceArray: sliceNo
      });

      //------

      let id = this.props.match.params.id;

      if (id) {
        let response2 = await axios({
          method: "get",
          url: `${URL.BASE_URL}/forms/singledata/${id}`,

          headers: { Authorization: `Bearer ${token}` }
        });

        this.setState({
          fieldValue: response2.data.data.fields,
          token: token,
          id: id
        });

        //console.log(this.state.fieldValue);
        //console.log(response.data.data);
      }
    }
  }

  handleChange(e) {
    var merged;
    let newItem;
    let oldItem = this.state.fieldValue;

    if (e.target.name == "patient_initials_436") {
      newItem = {
        [e.target.name]: e.target.value.toUpperCase()
      };
    } else {
      newItem = { [e.target.name]: e.target.value };
    }

    merged = { ...oldItem, ...newItem };
    this.setState({
      fieldValue: merged
    });
  }

  customDate = ({ date, name }) => {
    let mm = date ? date.split("-")[0] : null;
    let dd = date ? date.split("-")[1] : null;
    let yyyy = date ? date.split("-")[2] : null;
    const dayArr = Array.from(Array(31).keys());
    const monthArr = [
      // "Jan",
      // "Feb",
      // "Mar",
      // "Apr",
      // "May",
      // "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ];
    const yearArr = [2018, 2019, 2020, 2021, 2022, 2023];
    const optionStyle = {
      padding: "5px",
      borderRadius: "3px",
      borderColor: "#ccc",
      color: "#aaa"
    };

    return (
      <>
        <select
          onChange={this.handleChange}
          style={optionStyle}
          id="date"
          name={`${name}_dd`}
        >
          <option value={dd ? dd : ""}>{dd ? dd : "DD"}</option>
          {dayArr.map(item => {
            return <option value={item + 1}>{item + 1}</option>;
          })}
        </select>
        <select
          style={optionStyle}
          onChange={this.handleChange}
          id="month"
          name={`${name}_mm`}
        >
          <option value={mm ? mm : ""}>{mm ? monthArr[mm - 1] : "MM"}</option>

          {monthArr.map((item, key) => {
            return <option value={key + 1}>{item}</option>;
          })}
        </select>

        <select
          style={optionStyle}
          onChange={this.handleChange}
          id="year"
          name={`${name}_yyyy`}
        >
          <option value="2021">2021</option>
          {/* <option value={yyyy ? yyyy : ""}>{yyyy ? yyyy : "YYYY"}</option>

          {yearArr.map((item, key) => {
            return <option value={item}>{item}</option>;
          })} */}
        </select>
      </>
    );
  };

  customDate2 = ({ date, name }) => {
    let mm = date ? date.split("-")[0] : null;
    let dd = date ? date.split("-")[1] : null;
    let yyyy = date ? date.split("-")[2] : null;
    const dayArr = Array.from(Array(31).keys());
    const monthArr = [
      // "Jan",
      // "Feb",
      // "Mar",
      // "Apr",
      // "May",
      // "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov"
      // "Dec"
    ];
    const yearArr = [2018, 2019, 2020, 2021, 2022, 2023];
    const optionStyle = {
      padding: "5px",
      borderRadius: "3px",
      borderColor: "#ccc",
      color: "#aaa"
    };

    return (
      <>
        <select
          onChange={this.handleChange}
          style={optionStyle}
          id="date"
          name={`${name}_dd`}
        >
          <option value={dd ? dd : ""}>{dd ? dd : "DD"}</option>
          {dayArr.map(item => {
            return <option value={item + 1}>{item + 1}</option>;
          })}
        </select>
        <select
          style={optionStyle}
          onChange={this.handleChange}
          id="month"
          name={`${name}_mm`}
        >
          <option value={mm ? mm : ""}>{mm ? monthArr[mm - 1] : "MM"}</option>

          {monthArr.map((item, key) => {
            return <option value={key + 1}>{item}</option>;
          })}
        </select>

        <select
          style={optionStyle}
          onChange={this.handleChange}
          id="year"
          name={`${name}_yyyy`}
        >
          <option value="2021">2021</option>
          {/* <option value={yyyy ? yyyy : ""}>{yyyy ? yyyy : "YYYY"}</option>

          {yearArr.map((item, key) => {
            return <option value={item}>{item}</option>;
          })} */}
        </select>
      </>
    );
  };
  handleCheck(e) {
    let item = e.target.name;
    let value = e.target.value;
    let newItem = [];

    if (e.target.checked) {
      if (this.state[item]) {
        newItem = this.state[item];
      }
      newItem.push(value);
    } else {
      if (this.state[item]) {
        newItem = this.state[item];
      }
      newItem.pop(value);
    }

    this.setState({
      [item]: newItem
    });
  }

  nextData() {
    const valCheck = this.FieldValueCheck();
    console.log("valcheck---->", valCheck);
    this.setState({
      isLoading: true
    });

    setTimeout(
      function() {
        this.setState({ isLoading: false });
      }.bind(this),
      250
    );

    if (valCheck) {
      let oldStart = this.state.start;
      let oldEnd = this.state.end;
      this.setState({
        start: oldStart + 1,
        end: oldEnd + 1,
        showError: false
      });
      window.scrollTo(0, 0);
      this.firstSend(false);
    }
  }
  prevData() {
    let oldStart = this.state.start;
    let oldEnd = this.state.end;
    this.setState({
      start: oldStart - 1,
      end: oldEnd - 1
    });
    window.scrollTo(0, 0);
  }

  pagination(pageNo) {
    const valCheck = this.FieldValueCheck(true, pageNo);

    this.setState({
      isLoading: true
    });

    setTimeout(
      function() {
        this.setState({ isLoading: false });
      }.bind(this),
      250
    );

    if (valCheck) {
      this.setState({
        start: pageNo - 1,
        end: pageNo,
        showError: false
      });
      window.scrollTo(0, 0);
      this.firstSend(false);
    }
  }

  Validation({ min, max, value, name, type }) {
    if (name === "HDL (mg/dL)") {
      if (
        Number(value) > Number(this.state.fieldValue.total_cholesterol_mgdl_980)
      ) {
        return (
          <>
            <Alert
              theme="warning"
              style={{
                fontSize: "18px"
              }}
            >
              The value is greater than Total Cholesterol, please recheck.
            </Alert>
          </>
        );
      } else {
        return <div></div>;
      }
    } else if (name === "LDL (mg/dL)") {
      if (
        Number(value) > Number(this.state.fieldValue.total_cholesterol_mgdl_980)
      ) {
        return (
          <>
            <Alert
              theme="warning"
              style={{
                fontSize: "18px"
              }}
            >
              The value is greater than Total Cholesterol, please recheck.
            </Alert>
          </>
        );
      } else {
        return <div></div>;
      }
    } else if (type === "bp") {
      if (Number(value) < Number(min) || Number(value) > Number(max)) {
        return (
          <>
            <Alert
              theme="warning"
              style={{
                fontSize: "18px"
              }}
            >
              The Diastolic Pressure is more than Systolic Pressure, Please
              Recheck.
            </Alert>
          </>
        );
      } else {
        return <div></div>;
      }
    } else if (type === "HH" || type === "MM") {
      console.log("🚀🚀🚀", value);
      let timeMax = type === "HH" ? 23 : 59;
      if (value < 0 || value > timeMax) {
        return (
          <>
            <Alert
              theme="warning"
              style={{
                fontSize: "18px"
              }}
            >
              {name} - should be in between 0 to {timeMax}
            </Alert>
          </>
        );
      } else {
        return <div></div>;
      }
    } else if (name === "Date of Discharge / Date of Death") {
      let d1 = Date.parse(
        `2021-${this.state.fieldValue.date_of_admission_397_mm}-${this.state.fieldValue.date_of_admission_397_dd}`
      );
      let d2 = Date.parse(
        `2021-${this.state.fieldValue.date_of_discharge_411_mm}-${this.state.fieldValue.date_of_discharge_411_dd}`
      );
      const diffTime = Math.abs(d2 - d1);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      console.log(diffDays);
      if (d2 - d1 < 0) {
        return (
          <>
            <Alert
              theme="warning"
              style={{
                fontSize: "18px"
              }}
            >
              Date of discharge should be beyond the date of admission
            </Alert>
          </>
        );
      } else if (diffDays > 13) {
        return (
          <>
            <Alert
              theme="warning"
              style={{
                fontSize: "18px"
              }}
            >
              The Duration is more than 14 Days, Please Recheck
            </Alert>
          </>
        );
      } else {
        return <div></div>;
      }
    } else if (name === "Symptom onset date") {
      let d1 = Date.parse(
        `2021-${this.state.fieldValue.date_of_admission_397_mm}-${this.state.fieldValue.date_of_admission_397_dd}`
      );
      let d2 = Date.parse(
        `2021-${this.state.fieldValue.symptom_onset_460_mm}-${this.state.fieldValue.symptom_onset_460_dd}`
      );
      const diffTime = d2 - d1;
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      console.log(diffDays);
      if (diffTime > 0) {
        return (
          <>
            <Alert
              theme="warning"
              style={{
                fontSize: "18px"
              }}
            >
              Symptom onset date should be either equal to or before the date of
              admission
            </Alert>
          </>
        );
      } else {
        return <div></div>;
      }
    } else if (value < min || value > max) {
      return (
        <>
          <Alert
            theme="warning"
            style={{
              fontSize: "18px"
            }}
          >
            {name} - should be in between {min} to {max}
          </Alert>
        </>
      );
    } else {
      return <div></div>;
    }
  }

  MandatoryField({ value, value2, type, name, isMandatoryNot }) {
    let show = this.state.showError;
    if (type === "date") {
      if (show && !isMandatoryNot && (value === undefined || value === "")) {
        return (
          <>
            <Alert theme="danger">{name} field is mandatory</Alert>
          </>
        );
      } else {
        return <div> </div>;
      }
    } else if (type === "time") {
      if (show && !isMandatoryNot && (value === undefined || value === "")) {
        return (
          <>
            <Alert theme="danger">{name} - time field is mandatory</Alert>
          </>
        );
      } else {
        return <div> </div>;
      }
    } else if (type === "check_box") {
      let valueLen = 0;
      if (value2) {
        valueLen = value2.length;
      } else if (value) {
        valueLen = value.length;
      }
      if (show && !isMandatoryNot && valueLen === 0) {
        return (
          <>
            <Alert theme="danger">{name} - field is mandatory</Alert>
          </>
        );
      } else {
        return <div> </div>;
      }
    } else if (
      show &&
      !isMandatoryNot &&
      (value === undefined || value === "")
    ) {
      return (
        <>
          <Alert theme="danger">{name} - field is mandatory</Alert>
        </>
      );
    } else {
      return <div> </div>;
    }
  }

  FieldValueCheck(haveMultiPage, pageNo) {
    if (haveMultiPage && !this.props.match.params.id) {
      if (pageNo < this.state.end) {
        return true;
      }
      return false;
    }
    let fields = [];

    if (this.state.formFields) {
      fields = this.state.formFields;
    }
    let start = 0;
    let end = this.state.sliceArray[this.state.end];
    if (this.state.start > 0) {
      start = this.state.sliceArray[this.state.start];
    }

    let arrList = [];

    let sliceArr = fields.slice(start, end);

    sliceArr.map((field, key) => {
      console.log("✅❤💛💙🚀", field.fieldName);
      if (field.isPci) arrList.push(true);
      let isCheckBox = false;
      let checkBoxLength = 0;
      if (field.fieldType === "check_box" || field.fieldType === "check_box2") {
        isCheckBox = true;
        if (this.state[field.fieldSlug]) {
          checkBoxLength = this.state[field.fieldSlug].length;
        } else if (this.state.fieldValue[field.fieldSlug]) {
          checkBoxLength = this.state.fieldValue[field.fieldSlug].length;
        }
      }

      let value = this.state.fieldValue[field.fieldSlug];

      if (field.parentValue) {
        if (
          !Array.isArray(field.parentValue) &&
          this.state.fieldValue[field.parentSlug[0]] == field.parentValue &&
          (value === undefined || value === "") &&
          (field.fieldType === "text" ||
            field.fieldType === "number" ||
            // field.fieldType === "date" ||
            field.fieldType === "radio" ||
            field.fieldType === "check_box2" ||
            field.fieldType === "check_box" ||
            field.fieldType === "dropdown") &&
          !field.isMandatoryNot
        ) {
          if (isCheckBox) {
            if (checkBoxLength === 0) {
              arrList.push(false);
            } else {
              arrList.push(true);
            }
          } else {
            arrList.push(false);
          }
        } else if (
          Array.isArray(field.parentValue) &&
          this.state.fieldValue[field.parentSlug[0]] == field.parentValue[0] &&
          (value === undefined || value === "") &&
          (field.fieldType === "text" ||
            field.fieldType === "number" ||
            // field.fieldType === "date" ||
            field.fieldType === "radio" ||
            field.fieldType === "check_box2" ||
            field.fieldType === "check_box" ||
            field.fieldType === "dropdown") &&
          !field.isMandatoryNot
        ) {
          if (isCheckBox) {
            if (checkBoxLength === 0) {
              arrList.push(false);
            } else {
              arrList.push(true);
            }
          } else {
            arrList.push(true); //TODO:Validation Fix
          }
        } else {
          arrList.push(true);
        }
      } else if (
        !field.isMandatoryNot &&
        (value === undefined || value === "") &&
        field.parentValue === undefined &&
        (field.fieldType === "text" ||
          field.fieldType === "number" ||
          // field.fieldType === "date" ||
          field.fieldType === "radio" ||
          field.fieldType === "check_box2" ||
          field.fieldType === "check_box" ||
          field.fieldType === "dropdown")
      ) {
        if (isCheckBox) {
          if (checkBoxLength === 0) {
            arrList.push(false);
          } else {
            arrList.push(true);
          }
        } else {
          arrList.push(false);
        }
      } else if (
        field.parentValue !== undefined &&
        (value !== undefined || value !== "") &&
        (field.fieldType === "text" ||
          field.fieldType === "number" ||
          // field.fieldType === "date" ||
          field.fieldType === "check_box" ||
          field.fieldType === "check_box2" ||
          field.fieldType === "radio" ||
          field.fieldType === "dropdown")
      ) {
        if (isCheckBox) {
          if (checkBoxLength !== 0) {
            arrList.push(true);
          } else {
            arrList.push("dsdsd");
          }
        } else {
          arrList.push(true);
        }
      }
    });

    const validationCheck = arrList.every(value => value); // true for validation disabled

    if (!validationCheck) {
      this.setState({
        showError: true
      });
    }

    console.log("✅❤💛💙🚀", arrList);

    return validationCheck;
  }

  async firstSend(completed) {
    //e.preventDefault();

    console.log("HERE🚩🚩🚩🚩🚩");

    const token = Cookie.get("jwtToken") ? Cookie.get("jwtToken") : null;
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    let stateData = Object.assign({}, this.state);
    stateData.date_of_admission_397 = `${stateData.fieldValue.date_of_admission_397_mm}-${stateData.fieldValue.date_of_admission_397_dd}-2021`;
    stateData.date_of_discharge_411 = `${stateData.fieldValue.date_of_discharge_411_mm}-${stateData.fieldValue.date_of_discharge_411_dd}-2021`;
    stateData.date_of_death_if_applicable_774 = `${stateData.fieldValue.date_of_death_if_applicable_774_mm}-${stateData.fieldValue.date_of_death_if_applicable_774_dd}-2021`;
    stateData.symptom_onset_460 = `${stateData.fieldValue.symptom_onset_460_mm}-${stateData.fieldValue.symptom_onset_460_dd}-2021`;
    stateData.lysis_start_date_125 = `${stateData.fieldValue.lysis_start_date_125_mm}-${stateData.fieldValue.lysis_start_date_125_dd}-2021`;

    delete stateData.formFields;
    delete stateData.fieldValue;
    delete stateData.checkBoxValue;
    delete stateData.start;
    delete stateData.end;
    delete stateData.sliceArray;
    delete stateData.id;
    delete stateData.isCompleted;
    delete stateData.selectedFile;
    delete stateData.isLoading;
    delete stateData.showError;
    delete stateData.validation;

    let dataToSend = { ...this.state.fieldValue, ...stateData };

    let response;
    // if (this.state.start + 1 === this.state.sliceArray.length - 1) {
    //   dataToSend.isCompleted = true;
    //  }

    dataToSend.isCompleted = completed;

    if (this.state.id == null && this.state.start === 0) {
      response = await axios
        .post(`${URL.BASE_URL}/forms/adddata`, dataToSend, config)
        .catch(function(error) {
          console.log(error);
        });
      if (response.data.data) {
        this.setState({
          id: response.data.data._id
        });
      }
    } else if (this.state.id != null) {
      dataToSend.id = this.state.id;
      response = await axios
        .patch(`${URL.BASE_URL}/forms/updatedata`, dataToSend, config)
        .catch(function(error) {
          console.log(error);
        });
    }
  }

  redirectState() {
    const valCheck = this.FieldValueCheck();
    if (valCheck) {
      this.firstSend(true);
      this.setState({
        isCompleted: true
      });
    }
  }

  TopTab() {
    const isDesktopOrLaptop = useMediaQuery({ minDeviceWidth: 700 });

    if (isDesktopOrLaptop) {
      return (
        <div>
          <nav>
            <ul className="pagination">
              {this.state.sliceArray.map((item, index) => {
                let titleIndex = this.state.sliceArray;
                if (index === this.state.start + 1) {
                  return (
                    <li className="page-item ">
                      <Button
                        size="sm"
                        theme="info"
                        className="mb-2 mr-1"
                        onClick={() => this.pagination(index)}
                      >
                        {index} .
                        {this.state.formFields[titleIndex[index - 1]].fieldName}
                      </Button>
                    </li>
                  );
                } else if (index === 0) {
                  return <div></div>;
                } else {
                  return (
                    <li className="page-item">
                      <Button
                        size="sm"
                        outline
                        theme="info"
                        className="mb-2 mr-1"
                        onClick={() => this.pagination(index)}
                      >
                        {index} .
                        {this.state.formFields[titleIndex[index - 1]].fieldName}
                      </Button>
                    </li>
                  );
                }
              })}
            </ul>
          </nav>
        </div>
      );
    } else {
      return <div></div>;
    }
  }

  BottomButton() {
    const isDesktopOrLaptop = useMediaQuery({ minDeviceWidth: 700 });

    let start = this.state.start;
    let end = this.state.end;
    // console.log(this.state.sliceArray, start, end);
    let arrayLength = this.state.sliceArray.length;
    if (isDesktopOrLaptop) {
      if (start === 0) {
        return (
          <div>
            <nav>
              <ul className="pagination">
                {/* <li className="page-item">
                  <Button
                    size="sm"
                    theme="warning"
                    className="mb-2 mr-1"
                    onClick={this.prevData}
                  >
                    Prev
                  </Button>
                </li> */}
                {/*   {this.state.sliceArray.map((item, index) => {
                  if (index === this.state.start + 1) {
                    return (
                      <li className="page-item ">
                        <Button
                          size="sm"
                          theme="info"
                          className="mb-2 mr-1"
                          onClick={() => this.pagination(index)}
                        >
                          {index}
                        </Button>
                      </li>
                    );
                  } else if (index === 0) {
                    return <div></div>;
                  } else {
                    return (
                      <li className="page-item">
                        <Button
                          size="sm"
                          outline
                          theme="info"
                          className="mb-2 mr-1"
                          onClick={() => this.pagination(index)}
                        >
                          {index}
                        </Button>
                      </li>
                    );
                  }
                })} */}

                <li className="page-item">
                  <Button
                    size="sm"
                    theme="primary"
                    className="mb-2 mr-1"
                    onClick={this.nextData}
                  >
                    Save & Next
                  </Button>
                </li>
              </ul>
            </nav>
          </div>
        );
      } else if (start === arrayLength - 2 && end === arrayLength - 1) {
        return (
          <div>
            <nav aria-label="Page navigation example">
              <ul className="pagination">
                <li className="page-item">
                  <Button
                    size="sm"
                    theme="warning"
                    className="mb-2 mr-1"
                    onClick={this.prevData}
                  >
                    Prev
                  </Button>
                </li>
                {/*
                {this.state.sliceArray.map((item, index) => {
                  if (index === this.state.start + 1) {
                    return (
                      <li className="page-item ">
                        <Button
                          size="sm"
                          theme="info"
                          className="mb-2 mr-1"
                          onClick={() => this.pagination(index)}
                        >
                          {index}
                        </Button>
                      </li>
                    );
                  } else if (index === 0) {
                    return <div></div>;
                  } else {
                    return (
                      <li className="page-item">
                        <Button
                          size="sm"
                          outline
                          theme="info"
                          className="mb-2 mr-1"
                          onClick={() => this.pagination(index)}
                        >
                          {index}
                        </Button>
                      </li>
                    );
                  }
                })}
              */}

                <li className="page-item">
                  <Button
                    size="sm"
                    theme="success"
                    className="mb-2 mr-1"
                    onClick={this.redirectState}
                  >
                    Submit
                  </Button>
                </li>
              </ul>
            </nav>
          </div>
        );
      } else if (start > 0 && end < arrayLength) {
        return (
          <div>
            <nav aria-label="Page navigation example">
              <ul className="pagination">
                <li className="page-item">
                  <Button
                    size="sm"
                    theme="warning"
                    className="mb-2 mr-1"
                    onClick={this.prevData}
                  >
                    Prev
                  </Button>
                </li>
                {/*
                {this.state.sliceArray.map((item, index) => {
                  if (index === this.state.start + 1) {
                    return (
                      <li className="page-item ">
                        <Button
                          size="sm"
                          theme="info"
                          className="mb-2 mr-1"
                          onClick={() => this.pagination(index)}
                        >
                          {index}
                        </Button>
                      </li>
                    );
                  } else if (index === 0) {
                    return <div></div>;
                  } else {
                    return (
                      <li className="page-item">
                        <Button
                          size="sm"
                          outline
                          theme="info"
                          className="mb-2 mr-1"
                          onClick={() => this.pagination(index)}
                        >
                          {index}
                        </Button>
                      </li>
                    );
                  }
                })}*/}

                <li className="page-item">
                  <Button
                    size="sm"
                    theme="primary"
                    className="mb-2 mr-1"
                    onClick={this.nextData}
                  >
                    Save & Next
                  </Button>
                </li>
              </ul>
            </nav>
          </div>
        );
      } else {
        return <div>" "</div>;
      }
    } else {
      if (start === 0) {
        return (
          <div>
            <nav>
              <ul className="pagination">
                {/* <li className="page-item">
                  <Button
                    size="sm"
                    theme="warning"
                    className="mb-2 mr-1"
                    onClick={this.prevData}
                  >
                    Prev
                  </Button>
                </li> */}
                {/* {this.state.sliceArray.map((item, index) => {
                  if (index === this.state.start + 1) {
                    return (
                      <li className="page-item ">
                        <Button
                          size="sm"
                          theme="info"
                          className="mb-2 mr-1"
                          onClick={() => this.pagination(index)}
                        >
                          {index}
                        </Button>
                      </li>
                    );
                  } else if (index === 0) {
                    return <div></div>;
                  } else if (index < 5) {
                    return (
                      <li className="page-item">
                        <Button
                          size="sm"
                          outline
                          theme="info"
                          className="mb-2 mr-1"
                          onClick={() => this.pagination(index)}
                        >
                          {index}
                        </Button>
                      </li>
                    );
                  } else {
                    return <div></div>;
                  }
                })} */}

                <li className="page-item">
                  <Button
                    size="sm"
                    theme="primary"
                    className="mb-2 mr-1"
                    onClick={this.nextData}
                  >
                    Save & Next
                  </Button>
                </li>
              </ul>
            </nav>
          </div>
        );
      } else if (start === arrayLength - 2 && end === arrayLength - 1) {
        return (
          <div>
            <nav aria-label="Page navigation example">
              <ul className="pagination">
                <li className="page-item">
                  <Button
                    size="sm"
                    theme="warning"
                    className="mb-2 mr-1"
                    onClick={this.prevData}
                  >
                    Prev
                  </Button>
                </li>
                {/* {this.state.sliceArray.map((item, index) => {
                  if (index === this.state.start + 1) {
                    return (
                      <li className="page-item ">
                        <Button
                          size="sm"
                          theme="info"
                          className="mb-2 mr-1"
                          onClick={() => this.pagination(index)}
                        >
                          {index}
                        </Button>
                      </li>
                    );
                  } else if (index === 0) {
                    return <div></div>;
                  } else if (index > end - 4) {
                    return (
                      <li className="page-item">
                        <Button
                          size="sm"
                          outline
                          theme="info"
                          className="mb-2 mr-1"
                          onClick={() => this.pagination(index)}
                        >
                          {index}
                        </Button>
                      </li>
                    );
                  } else {
                    return <div></div>;
                  }
                })} */}

                <li className="page-item">
                  <Button
                    size="sm"
                    theme="success"
                    className="mb-2 mr-1"
                    onClick={this.redirectState}
                  >
                    Submit
                  </Button>
                </li>
              </ul>
            </nav>
          </div>
        );
      } else if (start > 0 && end < arrayLength) {
        return (
          <div>
            <nav aria-label="Page navigation example">
              <ul className="pagination">
                <li className="page-item">
                  <Button
                    size="sm"
                    theme="warning"
                    className="mb-2 mr-1"
                    onClick={this.prevData}
                  >
                    Prev
                  </Button>
                </li>
                {/* {this.state.sliceArray.map((item, index) => {
                  if (index === this.state.start + 1) {
                    return (
                      <li className="page-item ">
                        <Button
                          size="sm"
                          theme="info"
                          className="mb-2 mr-1"
                          onClick={() => this.pagination(index)}
                        >
                          {index}
                        </Button>
                      </li>
                    );
                  } else if (index === 0) {
                    return <div></div>;
                  } else if (index < end + 3 && index > end - 3) {
                    return (
                      <li className="page-item">
                        <Button
                          size="sm"
                          outline
                          theme="info"
                          className="mb-2 mr-1"
                          onClick={() => this.pagination(index)}
                        >
                          {index}
                        </Button>
                      </li>
                    );
                  } else {
                    return <div></div>;
                  }
                })} */}

                <li className="page-item">
                  <Button
                    size="sm"
                    theme="primary"
                    className="mb-2 mr-1"
                    onClick={this.nextData}
                  >
                    Save & Next
                  </Button>
                </li>
              </ul>
            </nav>
          </div>
        );
      } else {
        return <div>" "</div>;
      }
    }
  }

  CustomRadio({ name, item }) {
    if (item === this.state.fieldValue[name]) {
      return (
        <>
          <input
            type="radio"
            id="radio1"
            name={name || "name"}
            value={item || "item"}
            onChange={this.handleChange}
            style={{
              marginTop: "2px",
              marginRight: "10px",
              fontSize: "16px",
              border: "0px",
              width: "20px",
              height: "20px"
            }}
            defaultChecked
          ></input>
          {item}
        </>
      );
    } else {
      return (
        <>
          <input
            type="radio"
            id="radio1"
            name={name || "name"}
            value={item || "item"}
            onChange={this.handleChange}
            style={{
              marginTop: "2px",
              marginRight: "10px",

              fontSize: "16px",
              border: "0px",
              width: "20px",
              height: "20px"
            }}
          ></input>
          {item}
        </>
      );
    }
  }

  CustomCheckBox({ name, item }) {
    let arr = [];
    if (this.state[name]) {
      arr = this.state[name];
    } else if (this.state.fieldValue[name]) {
      arr = this.state.fieldValue[name];
    }
    // console.log(name, item);
    let isChecked = arr.includes(item);
    return (
      <>
        <Col md="12">
          <input
            type="checkbox"
            id="checkbox1"
            style={{
              fontSize: "16px",
              border: "0px",
              width: "20px",
              height: "20px"
            }}
            defaultChecked={isChecked}
            name={name}
            value={item}
            onChange={this.handleCheck}
          />
          &nbsp; &nbsp; &nbsp;
          {item}
        </Col>
      </>
    );
  }

  toggle = () => {
    this.setState({
      open: !this.state.open
    });
  };

  maxLengthCheck = object => {
    if (object.target.value.length > object.target.maxLength) {
      object.target.value = object.target.value.slice(
        0,
        object.target.maxLength
      );
    }
  };
  onInputChange(e) {
    this.setState({
      inputValue: e.target.value
    });
  }

  RenderFields() {
    //For Second page other if condition
    let arr = [];

    var found;
    //   console.log(`%c --------------- `, "background: #dda; color: #ff0000");// color console
    //endd --------------------------------------
    let fields = [];

    if (this.state.formFields) {
      fields = this.state.formFields;
    }

    let start = 0;
    let end = this.state.sliceArray[this.state.end];
    if (this.state.start > 0) {
      start = this.state.sliceArray[this.state.start];
    }

    return (
      <div>
        {fields.slice(start, end).map((field, key) => {
          var parent_name;
          var parent_value = field.parentValue;
          var parent_curent_value;
          var parent_curent_value_array = [];
          var parent_name_array = [];
          var parent_value_array = [];

          let arr = []; // for death
          let arr3 = []; //for alive
          var found2;

          if (this.state["cause_of_death_169"]) {
            found2 = this.state["cause_of_death_169"];
          } else if (this.state.fieldValue["cause_of_death_169"]) {
            found2 = this.state.fieldValue["cause_of_death_169"];
          }

          if (this.state["cause_of_death_169"]) {
            arr = this.state["cause_of_death_169"];
          } else if (this.state.fieldValue["cause_of_death_169"]) {
            arr = this.state.fieldValue["cause_of_death_169"];
          }

          if (this.state["major_complications_177"]) {
            arr3 = this.state["major_complications_177"];
          } else if (this.state.fieldValue["major_complications_177"]) {
            arr3 = this.state.fieldValue["major_complications_177"];
          }

          var f1;
          var f2;
          var f3;

          if (arr) {
            f1 = arr.includes("Stroke");
            f2 = arr.includes("Mechanical Complications");
            f3 = arr.includes("Fatal Bleeding");
          }

          var f4;
          var f5;
          var f6;

          if (arr) {
            f4 = arr3.includes("Stroke");
            f5 = arr3.includes("Mechanical Complications");
            f6 = arr3.includes("Fatal Bleeding");
          }

          let arr2 = [];
          if (this.state["aetiology_of_hf_886"]) {
            arr2 = this.state["aetiology_of_hf_886"];
          } else if (this.state.fieldValue["aetiology_of_hf_886"]) {
            arr2 = this.state.fieldValue["aetiology_of_hf_886"];
          }

          var found3;
          if (arr2) {
            found3 = arr2.includes("Others");
          }

          let colSize = { size: 6, offset: field.parentValue ? 1 : 0 };
          let radioColSize = { size: 4, offset: field.parentValue ? 1 : 0 };

          let deadAlive = this.state.fieldValue.alive_dead_757;

          if (
            field.fieldSlug === "type_of_stroke_330" &&
            f1 &&
            deadAlive === "Dead"
          ) {
            return (
              <>
                <Row>
                  <Col md={radioColSize}>
                    {field.fieldName}{" "}
                    <strong style={{ color: "red" }}>
                      {" "}
                      {field.isMandatoryNot ? " " : "*"}
                    </strong>{" "}
                    :{" "}
                  </Col>
                  {field.radioEnum.map(item => {
                    return (
                      <>
                        <this.CustomRadio name={field.fieldSlug} item={item} />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </>
                    );
                  })}
                </Row>
                <hr />
              </>
            );
          }

          if (field.fieldSlug === "specify_848" && f2 && deadAlive === "Dead") {
            return (
              <>
                <Row>
                  <Col md={radioColSize}>
                    {field.fieldName}{" "}
                    <strong style={{ color: "red" }}>
                      {" "}
                      {field.isMandatoryNot ? " " : "*"}
                    </strong>{" "}
                    :{" "}
                  </Col>
                  {field.radioEnum.map(item => {
                    return (
                      <>
                        <this.CustomRadio name={field.fieldSlug} item={item} />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </>
                    );
                  })}
                </Row>
                <hr />
              </>
            );
          }

          if (
            field.fieldSlug === "fatal_bleeding_874" &&
            f3 &&
            deadAlive === "Dead"
          ) {
            return (
              <>
                <Row>
                  <Col md={radioColSize}>
                    {field.fieldName}{" "}
                    <strong style={{ color: "red" }}>
                      {" "}
                      {field.isMandatoryNot ? " " : "*"}
                    </strong>{" "}
                    :{" "}
                  </Col>
                  {field.radioEnum.map(item => {
                    return (
                      <>
                        <this.CustomRadio name={field.fieldSlug} item={item} />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </>
                    );
                  })}
                </Row>
                <hr />
              </>
            );
          }

          if (field.fieldSlug === "type_of_stroke_341" && f4) {
            return (
              <>
                <Row>
                  <Col md={radioColSize}>
                    {field.fieldName}{" "}
                    <strong style={{ color: "red" }}>
                      {" "}
                      {field.isMandatoryNot ? " " : "*"}
                    </strong>{" "}
                    :{" "}
                  </Col>
                  {field.radioEnum.map(item => {
                    return (
                      <>
                        <this.CustomRadio name={field.fieldSlug} item={item} />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </>
                    );
                  })}
                </Row>
                <hr />
              </>
            );
          }

          if (
            field.fieldSlug ===
              "if_yes_to_mechanical_complications_specify_321" &&
            f5
          ) {
            return (
              <>
                <Row>
                  <Col md={radioColSize}>
                    {field.fieldName}{" "}
                    <strong style={{ color: "red" }}>
                      {" "}
                      {field.isMandatoryNot ? " " : "*"}
                    </strong>{" "}
                    :{" "}
                  </Col>
                  {field.radioEnum.map(item => {
                    return (
                      <>
                        <this.CustomRadio name={field.fieldSlug} item={item} />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </>
                    );
                  })}
                </Row>
                <hr />
              </>
            );
          }

          if (field.fieldSlug === "fatal_bleeding_606" && f6) {
            return (
              <>
                <Row>
                  <Col md={radioColSize}>
                    {field.fieldName}{" "}
                    <strong style={{ color: "red" }}>
                      {" "}
                      {field.isMandatoryNot ? " " : "*"}
                    </strong>{" "}
                    :{" "}
                  </Col>
                  {field.radioEnum.map(item => {
                    return (
                      <>
                        <this.CustomRadio name={field.fieldSlug} item={item} />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </>
                    );
                  })}
                </Row>
                <hr />
              </>
            );
          }

          if (field.fieldSlug === "bp_mm_hg_810") {
            return (
              <>
                <this.MandatoryField
                  isMandatoryNot={field.isMandatoryNot}
                  value={this.state.fieldValue[field.fieldSlug]}
                  name={field.fieldName}
                />{" "}
                <Col md={colSize} sm="12">
                  <FormGroup>
                    <InputGroup className="mb-3">
                      <InputGroupAddon type="prepend">
                        <InputGroupText
                          style={{
                            color: "#99a",
                            "font-size": "14px",
                            "font-weight": "500"
                          }}
                        >
                          {field.fieldName}{" "}
                        </InputGroupText>
                      </InputGroupAddon>
                      <FormInput
                        type="number"
                        name={field.fieldSlug + "sys"}
                        onChange={this.handleChange}
                        value={this.state.fieldValue[field.fieldSlug + "sys"]}
                        min={field.minValue}
                        max={field.maxValue}
                        maxLength="3"
                        onInput={this.maxLengthCheck}
                      ></FormInput>
                      &nbsp;&nbsp;&nbsp; / &nbsp;&nbsp;&nbsp;
                      <FormInput
                        type="number"
                        name={field.fieldSlug + "dys"}
                        onChange={this.handleChange}
                        value={this.state.fieldValue[field.fieldSlug + "dys"]}
                        min={field.minValue}
                        max={field.maxValue}
                        maxLength="3"
                        onInput={this.maxLengthCheck}
                      ></FormInput>
                    </InputGroup>
                  </FormGroup>
                  <this.Validation
                    min={field.minValue}
                    max={this.state.fieldValue[field.fieldSlug + "sys"]}
                    value={this.state.fieldValue[field.fieldSlug + "dys"]}
                    name={field.fieldName}
                    type="bp"
                  />{" "}
                </Col>
                <hr />
              </>
            );
          }

          if (field.fieldSlug === "patient_initials_436") {
            return (
              <>
                <this.MandatoryField
                  isMandatoryNot={field.isMandatoryNot}
                  value={this.state.fieldValue[field.fieldSlug]}
                  name={field.fieldName}
                />{" "}
                <Col md={colSize} sm="6">
                  <FormGroup>
                    <InputGroup className="mb-3">
                      <InputGroupAddon type="prepend">
                        <InputGroupText
                          style={{
                            color: "#99a",
                            "font-size": "14px",
                            "font-weight": "500"
                          }}
                        >
                          {field.fieldName}{" "}
                          <strong style={{ color: "red" }}>
                            {" "}
                            {field.isMandatoryNot ? " " : "*"}
                          </strong>
                        </InputGroupText>
                      </InputGroupAddon>
                      <FormInput
                        type="text"
                        name={field.fieldSlug}
                        onChange={this.handleChange}
                        value={this.state.fieldValue[field.fieldSlug]}
                        maxLength="3"
                        style={{ textTransform: "uppercase" }}
                      ></FormInput>
                    </InputGroup>
                    <p>* Patients Initials should be in letters</p>
                  </FormGroup>
                  <this.Validation
                    min={field.minValue}
                    max={field.maxValue}
                    value={this.state.fieldValue[field.fieldSlug]}
                    name={field.fieldName}
                  />{" "}
                </Col>
                <hr />
              </>
            );
          }

          if (field.fieldSlug === "contact_phone_1_948") {
            return (
              <>
                <this.MandatoryField
                  isMandatoryNot={field.isMandatoryNot}
                  value={this.state.fieldValue[field.fieldSlug]}
                  name={field.fieldName}
                />{" "}
                <Col md={colSize} sm="6">
                  <FormGroup>
                    <InputGroup className="mb-3">
                      <InputGroupAddon type="prepend">
                        <InputGroupText
                          style={{
                            color: "#99a",
                            "font-size": "14px",
                            "font-weight": "500"
                          }}
                        >
                          {field.fieldName}{" "}
                          <strong style={{ color: "red" }}>
                            {" "}
                            {field.isMandatoryNot ? " " : "*"}
                          </strong>
                        </InputGroupText>
                      </InputGroupAddon>
                      <FormInput
                        type="number"
                        name={field.fieldSlug}
                        onChange={this.handleChange}
                        value={this.state.fieldValue[field.fieldSlug]}
                        maxLength="10"
                        onInput={this.maxLengthCheck}
                      ></FormInput>
                    </InputGroup>
                  </FormGroup>
                  <this.Validation
                    min={field.minValue}
                    max={field.maxValue}
                    value={this.state.fieldValue[field.fieldSlug]}
                    name={field.fieldName}
                  />{" "}
                </Col>
                <hr />
              </>
            );
          }
          //custom date 2

          if (field.fieldSlug === "date_of_admission_397" || field.fieldSlug === "symptom_onset_460") {
            let mm = this.state.fieldValue[`${field.fieldSlug}_mm`];
            let dd = this.state.fieldValue[`${field.fieldSlug}_dd`];
            return (
              <>
                <this.MandatoryField
                  isMandatoryNot={field.isMandatoryNot}
                  value={
                    this.state.fieldValue[field.fieldSlug + "_mm"] &&
                    this.state.fieldValue[field.fieldSlug + "_dd"]
                  }
                  name={field.fieldName}
                  type="date"
                />
                <Col md={radioColSize + 2}>
                  <label htmlFor="date">
                    {" "}
                    {field.fieldName}{" "}
                    <strong style={{ color: "red" }}>
                      {" "}
                      {field.isMandatoryNot ? " " : "*"}
                    </strong>{" "}
                  </label>
                  &nbsp;:&nbsp; &nbsp; &nbsp; &nbsp;
                  <this.customDate2
                    name={field.fieldSlug}
                    date={`${mm}-${dd}-2021`}
                  />
                </Col>
                <p>{field.notes}</p>
                <this.Validation
                  min={field.minValue}
                  max={field.maxValue}
                  value={this.state.fieldValue[field.fieldSlug]}
                  name={field.fieldName}
                />{" "}
                <hr />
              </>
            );
          }

          if (field.parentSlug.length === 1) {
            parent_name = field.parentSlug[0];
            parent_curent_value = this.state.fieldValue[parent_name];
            //  console.log(parent_name);
            //  console.log(parent_value);
            //  console.log(parent_curent_value);
          }
          if (field.parentSlug.length > 1) {
            parent_name_array = field.parentSlug;
            parent_value_array = field.parentValue;
            parent_name_array.forEach(parent_item => {
              parent_curent_value_array.push(
                this.state.fieldValue[parent_item]
              );
            });
            {
              /*
            console.log("||||||||||||||||||||||||||||");
            console.log("length", field.parentSlug.length);
            console.log(parent_curent_value_array);
            console.log("array", parent_value_array);
            console.log(
              "Valuse",
              JSON.stringify(parent_value_array) ==
                JSON.stringify(parent_curent_value_array)
            );
            */
            }
          }

          let pciValue;

          if (this.state.type_of_pci_780) {
            pciValue = this.state.type_of_pci_780;
          } else if (this.state.fieldValue.type_of_pci_780) {
            pciValue = this.state.fieldValue.type_of_pci_780;
          }

          let ifPci;
          if (this.state.if_pci_780) {
            ifPci = this.state.if_pci_780;
          } else if (this.state.fieldValue.if_pci_780) {
            ifPci = this.state.fieldValue.if_pci_780;
          }

          let repValue;

          if (this.state.reperfusion_therapys_733) {
            repValue = this.state.reperfusion_therapys_733;
          } else if (this.state.fieldValue.reperfusion_therapys_733) {
            repValue = this.state.fieldValue.reperfusion_therapys_733;
          }

          let NCP = this.state.non_culprit_vessels_577
            ? this.state.non_culprit_vessels_577
            : this.state.fieldValue.non_culprit_vessels_577;
          let ICAT = this.state.intra_coronary_adjunct_therapy_980
            ? this.state.intra_coronary_adjunct_therapy_980
            : this.state.fieldValue.intra_coronary_adjunct_therapy_980;
          let COPM = this.state.complications_903
            ? this.state.complications_903
            : this.state.fieldValue.complications_903;
          let CAG = this.state.cag_done_286
            ? this.state.cag_done_286
            : this.state.fieldValue.cag_done_286;
          if (
            field.isPci &&
            (repValue === "Thrombolysis" ||
              repValue === "No Reperfusion Therapy") &&
            (pciValue === "Rescue PCI" ||
              pciValue === "Systematic early PCI" ||
              pciValue === "Delayed PCI" ||
              ifPci === "PCI" ||
              ifPci === "No PCI") &&
            CAG === "Yes"
          ) {
            if (ifPci === "No PCI") {
              if (
                field.fieldSlug === "wire_in_24_hour_clock_936" ||
                field.fieldSlug === "revascularization_415" ||
                field.fieldSlug === "intra_coronary_adjunct_therapy_980"
              ) {
                return (
                  <div>
                    {/* ***************
                    <br />
                    "field Name" : {field.fieldName} <br />
                    "Pos" : {field.pos}
                    "Parent Count" : {field.parentSlug.length}
                    {JSON.stringify(parent_curent_value_array)}
                    ************************ */}
                  </div>
                );
              }
            }
            if (field.parentSlug.length > 1 && NCP !== "Lesion") {
              return (
                <div>
                  {/* "field Name" : {field.fieldName} <br />
                  "Pos" : {field.pos}
                  "Parent Count" : {field.parentSlug.length}
                  {JSON.stringify(parent_curent_value_array)}
                  <br />
                  ICAT */}
                </div>
              );
            }

            if (
              ICAT !== "Yes" &&
              (field.fieldSlug === "gp2b3a_antagonists_990" ||
                field.fieldSlug === "vasodilators_953" ||
                field.fieldSlug === "intracoronary_thrombolytic_therapy_780")
            ) {
              return (
                <div>
                  {/* ***************
                  <br />
                  "field Name" : {field.fieldName} <br />
                  "Pos" : {field.pos}
                  "Parent Count" : {field.parentSlug.length}
                  {JSON.stringify(parent_curent_value_array)}
                  ************************ */}
                </div>
              );
            }

            if (COPM !== "Yes" && field.fieldSlug === "if_yes_110") {
              return (
                <div>
                  {/* ***************
                  <br />
                  "field Name" : {field.fieldName} <br />
                  "Pos" : {field.pos}
                  "Parent Count" : {field.parentSlug.length}
                  {JSON.stringify(parent_curent_value_array)}
                  ************************ */}
                </div>
              );
            }
            if (field.fieldType === "time") {
              return (
                <>
                  <this.MandatoryField
                    isMandatoryNot={field.isMandatoryNot}
                    type="time"
                    name={field.fieldName}
                    value={
                      this.state.fieldValue[field.fieldSlug + "HH"] &&
                      this.state.fieldValue[field.fieldSlug + "MM"]
                    }
                  />{" "}
                  <Col md="12" sm="12">
                    <Row>
                      <Col lg="3" sm="12">
                        <InputGroupText
                          style={{
                            color: "#99a",
                            "font-size": "14px",
                            "font-weight": "500"
                          }}
                        >
                          {field.fieldName}{" "}
                          <strong style={{ color: "red" }}>
                            {" "}
                            {field.isMandatoryNot ? " " : "*"}
                          </strong>
                        </InputGroupText>
                      </Col>
                      <Col lg="2" sm="12">
                        <FormInput
                          type="number"
                          name={field.fieldSlug + "HH"}
                          onChange={this.handleChange}
                          value={this.state.fieldValue[field.fieldSlug + "HH"]}
                          min={field.minValue}
                          placeholder="Hour"
                          max={field.maxValue}
                          maxLength="2"
                          onInput={this.maxLengthCheck}
                        ></FormInput>
                        {/* &nbsp;&nbsp;&nbsp; : &nbsp;&nbsp;&nbsp; */}
                      </Col>
                      <Col lg="2" sm="12">
                        <FormInput
                          type="number"
                          name={field.fieldSlug + "MM"}
                          onChange={this.handleChange}
                          placeholder="Minute"
                          value={this.state.fieldValue[field.fieldSlug + "MM"]}
                          min={field.minValue}
                          max={field.maxValue}
                          maxLength="2"
                          onInput={this.maxLengthCheck}
                        ></FormInput>
                      </Col>
                    </Row>
                    <this.Validation
                      min={field.minValue}
                      max={field.maxValue}
                      value={this.state.fieldValue[field.fieldSlug]}
                      name={field.fieldName}
                    />{" "}
                    <this.Validation
                      min={field.minValue}
                      max={field.maxValue}
                      value={this.state.fieldValue[field.fieldSlug]}
                      name={field.fieldName}
                    />{" "}
                  </Col>
                  <hr />
                </>
              );
            } else if (field.fieldType === "sub_title") {
              return (
                <>
                  <Col md={colSize}>
                    <h3>
                      {" "}
                      <strong> {field.fieldName} </strong>{" "}
                    </h3>
                    {pciValue === "Rescue PCI"
                      ? "If no successful reperfusion and done within 24 hours of lysis"
                      : pciValue === "Systematic early PCI"
                      ? "If done within 24 hours of lysis as part of Pharmaco - invasive strategy"
                      : pciValue === "Delayed PCI"
                      ? "If done after 24 hours of lysis"
                      : " "}
                    <hr />
                  </Col>
                </>
              );
            } else if (field.fieldType === "radio") {
              return (
                <>
                  <this.MandatoryField
                    isMandatoryNot={field.isMandatoryNot}
                    value={this.state.fieldValue[field.fieldSlug]}
                    name={field.fieldName}
                  />
                  <Row>
                    <Col md={radioColSize}>
                      {field.fieldName}{" "}
                      <strong style={{ color: "red" }}>
                        {" "}
                        {field.isMandatoryNot ? " " : "*"}
                      </strong>{" "}
                      :{" "}
                    </Col>
                    {field.radioEnum.map(item => {
                      return (
                        <>
                          <this.CustomRadio
                            name={field.fieldSlug}
                            item={item}
                          />
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </>
                      );
                    })}
                  </Row>
                  <hr />
                </>
              );
            } else if (field.fieldType === "dropdown") {
              return (
                <>
                  <this.MandatoryField
                    isMandatoryNot={field.isMandatoryNot}
                    value={this.state.fieldValue[field.fieldSlug]}
                    name={field.fieldName}
                  />
                  <Col md={colSize}>
                    <label htmlFor="feInputState">
                      {" "}
                      {field.fieldName}{" "}
                      <strong style={{ color: "red" }}>
                        {" "}
                        {field.isMandatoryNot ? " " : "*"}
                      </strong>{" "}
                    </label>
                    :{" "}
                    <FormSelect
                      id="feInputState"
                      name={field.fieldSlug}
                      onChange={this.handleChange}
                    >
                      <option>
                        {this.state.fieldValue[field.fieldSlug]
                          ? this.state.fieldValue[field.fieldSlug]
                          : "Select"}
                      </option>
                      {field.checkBoxEnum.map(item => {
                        return (
                          <>
                            <option>{item}</option>
                          </>
                        );
                      })}
                      <option value="">Select</option>
                    </FormSelect>
                    <p>{field.notes}</p>
                  </Col>
                  <hr />
                </>
              );
            } else if (field.fieldType === "text") {
              return (
                <>
                  <Col lg="6" md={colSize} sm="12">
                    <this.MandatoryField
                      isMandatoryNot={field.isMandatoryNot}
                      value={this.state.fieldValue[field.fieldSlug]}
                      name={field.fieldName}
                    />
                    <FormGroup>
                      <InputGroup className="mb-3">
                        <InputGroupAddon type="prepend">
                          <InputGroupText
                            style={{
                              color: "#99a",
                              "font-size": "14px",
                              "font-weight": "500"
                            }}
                          >
                            {field.fieldName}{" "}
                            <strong style={{ color: "red" }}>
                              {field.isMandatoryNot ? " " : "*"}
                            </strong>
                          </InputGroupText>
                        </InputGroupAddon>
                        <FormInput
                          type="text"
                          name={field.fieldSlug}
                          onChange={this.handleChange}
                          value={this.state.fieldValue[field.fieldSlug]}
                        ></FormInput>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <hr />
                </>
              );
            } else if (field.fieldType === "check_box") {
              return (
                <>
                  <this.MandatoryField
                    isMandatoryNot={field.isMandatoryNot}
                    value={this.state.fieldValue[field.fieldSlug]}
                    name={field.fieldName}
                    type="check_box"
                    value2={this.state[field.fieldSlug]}
                  />
                  <Row>
                    <Col
                      md={12}
                      style={{
                        fontSize: "18px",
                        marginBottom: "20px"
                      }}
                    >
                      {" "}
                      {field.fieldName}{" "}
                    </Col>

                    {field.checkBoxEnum.map((item, key) => {
                      let arr = [];
                      let name = field.fieldSlug;
                      if (this.state[name]) {
                        arr = this.state[name];
                      } else if (this.state.fieldValue[name]) {
                        arr = this.state.fieldValue[name];
                      }
                      // console.log(name, item);
                      let isChecked = arr.includes(item);
                      let disabled = arr.includes("None of the above");

                      if (disabled) {
                        if (
                          arr.includes("None of the above") &&
                          item === "None of the above"
                        ) {
                          disabled = false;
                        }
                        // else if (
                        //   arr.includes("Unknown") &&
                        //   item === "Unknown"
                        // ) {
                        //   disabled = false;
                        // }
                      }
                      return (
                        <>
                          <Col md="6">
                            <input
                              type="checkbox"
                              id="checkbox1"
                              style={{
                                fontSize: "16px",
                                border: "0px",
                                width: "20px",
                                height: "20px"
                              }}
                              defaultChecked={isChecked}
                              name={name}
                              value={item}
                              onChange={this.handleCheck}
                              disabled={disabled}
                            />
                            &nbsp; &nbsp; &nbsp;
                            {item}
                          </Col>
                        </>
                      );
                    })}
                  </Row>

                  <hr />
                </>
              );
            } else if (field.fieldType === "date") {
              let mm = this.state.fieldValue[`${field.fieldSlug}_mm`];
              let dd = this.state.fieldValue[`${field.fieldSlug}_dd`];

              return (
                <>
                  <this.MandatoryField
                    isMandatoryNot={field.isMandatoryNot}
                    value={
                      this.state.fieldValue[field.fieldSlug + "_mm"] &&
                      this.state.fieldValue[field.fieldSlug + "_dd"]
                    }
                    name={field.fieldName}
                    type="date"
                  />
                  <Col md={radioColSize + 2}>
                    <label htmlFor="date">
                      {" "}
                      {field.fieldName}{" "}
                      <strong style={{ color: "red" }}>
                        {" "}
                        {field.isMandatoryNot ? " " : "*"}
                      </strong>{" "}
                    </label>
                    &nbsp;:&nbsp; &nbsp; &nbsp; &nbsp;
                    <this.customDate
                      name={field.fieldSlug}
                      date={`${mm}-${dd}-2021`}
                    />
                  </Col>
                  <p>{field.notes}</p>
                  <this.Validation
                    min={field.minValue}
                    max={field.maxValue}
                    value={this.state.fieldValue[field.fieldSlug]}
                    name={field.fieldName}
                  />{" "}
                  <hr />
                </>
              );
            }
          }

          if (parent_name && parent_curent_value !== parent_value) {
            return (
              <div>
                {/*  "parent_name" : {parent_name} <br />
                "parent_value" : {parent_value} <br />
                "parent_current_value" : {parent_curent_value}
                */}
              </div>
            );
          } else if (
            field.parentSlug.length > 1 &&
            JSON.stringify(parent_value_array) !==
              JSON.stringify(parent_curent_value_array)
          ) {
            return (
              <div>
                {/* 
                "field Name" : {field.fieldName} <br />
                "Pos" : {field.pos}
                "Parent Count" : {field.parentSlug.length}
                 */}
              </div>
            );
          } else if (field.fieldSlug === "patient_id_793") {
            return (
              <>
                <Col lg="5" sm="12">
                  <FormGroup>
                    <InputGroup className="mb-3">
                      <InputGroupAddon type="prepend">
                        <InputGroupText
                          style={{
                            color: "#99a",
                            "font-size": "14px",
                            "font-weight": "500"
                          }}
                        >
                          {field.fieldName}{" "}
                        </InputGroupText>
                      </InputGroupAddon>
                      <FormInput
                        type="text"
                        name={field.fieldSlug}
                        onChange={this.handleChange}
                        value={this.state.fieldValue.patient_id_793}
                        disabled
                      ></FormInput>
                    </InputGroup>
                  </FormGroup>
                  <p>{field.notes}</p>
                </Col>
                <hr />
              </>
            );
          } else if (field.fieldType === "text") {
            return (
              <>
                <Col lg="6" md={colSize} sm="12">
                  <this.MandatoryField
                    isMandatoryNot={field.isMandatoryNot}
                    value={this.state.fieldValue[field.fieldSlug]}
                    name={field.fieldName}
                  />
                  <FormGroup>
                    <InputGroup className="mb-3">
                      <InputGroupAddon type="prepend">
                        <InputGroupText
                          style={{
                            color: "#99a",
                            "font-size": "14px",
                            "font-weight": "500"
                          }}
                        >
                          {field.fieldName}{" "}
                          <strong style={{ color: "red" }}>
                            {field.isMandatoryNot ? " " : "*"}
                          </strong>
                        </InputGroupText>
                      </InputGroupAddon>
                      <FormInput
                        type="text"
                        name={field.fieldSlug}
                        onChange={this.handleChange}
                        value={this.state.fieldValue[field.fieldSlug]}
                      ></FormInput>
                    </InputGroup>
                  </FormGroup>
                  <p>{field.notes}</p>
                </Col>
                <hr />
              </>
            );
          } else if (field.fieldType === "number") {
            return (
              <>
                <this.MandatoryField
                  isMandatoryNot={field.isMandatoryNot}
                  value={this.state.fieldValue[field.fieldSlug]}
                  name={field.fieldName}
                />{" "}
                <Col md={colSize} sm="12">
                  <FormGroup>
                    <InputGroup className="mb-3">
                      <InputGroupAddon type="prepend">
                        <InputGroupText
                          style={{
                            color: "#99a",
                            "font-size": "14px",
                            "font-weight": "500"
                          }}
                        >
                          {field.fieldName}{" "}
                          <strong style={{ color: "red" }}>
                            {" "}
                            {field.isMandatoryNot ? " " : "*"}
                          </strong>
                        </InputGroupText>
                      </InputGroupAddon>
                      <FormInput
                        type="number"
                        name={field.fieldSlug}
                        onChange={this.handleChange}
                        value={this.state.fieldValue[field.fieldSlug]}
                        min={field.minValue}
                        max={field.maxValue}
                        maxLength={field.maxLength ? field.maxLength : 10}
                        onInput={this.maxLengthCheck}
                      ></FormInput>
                    </InputGroup>
                  </FormGroup>
                  <p>{field.notes}</p>
                  <this.Validation
                    min={field.minValue}
                    max={field.maxValue}
                    value={this.state.fieldValue[field.fieldSlug]}
                    name={field.fieldName}
                  />{" "}
                </Col>
                <hr />
              </>
            );
          } else if (field.fieldType === "radio") {
            return (
              <>
                <this.MandatoryField
                  isMandatoryNot={field.isMandatoryNot}
                  value={this.state.fieldValue[field.fieldSlug]}
                  name={field.fieldName}
                />
                <Row>
                  <Col md={radioColSize}>
                    {field.fieldName}{" "}
                    <strong style={{ color: "red" }}>
                      {" "}
                      {field.isMandatoryNot ? " " : "*"}
                    </strong>{" "}
                    :{" "}
                  </Col>
                  {field.radioEnum.map(item => {
                    return (
                      <>
                        <this.CustomRadio name={field.fieldSlug} item={item} />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </>
                    );
                  })}
                  <p>{field.notes}</p>
                </Row>
                <hr />
              </>
            );
          } else if (field.fieldType === "check_box2") {
            return (
              <>
                <this.MandatoryField
                  isMandatoryNot={field.isMandatoryNot}
                  value={this.state.fieldValue[field.fieldSlug]}
                  value2={this.state[field.fieldSlug]}
                  name={field.fieldName}
                  type="check_box"
                />
                <Row>
                  <Col
                    md={12}
                    style={{
                      fontSize: "18px",
                      marginBottom: "20px"
                    }}
                  >
                    {" "}
                    {field.fieldName}{" "}
                    <strong style={{ color: "red" }}>
                      {" "}
                      {field.isMandatoryNot ? " " : "*"}
                    </strong>
                  </Col>

                  {field.checkBoxEnum.map((item, key) => {
                    return (
                      <>
                        <Col md="4">
                          <this.CustomCheckBox
                            name={field.fieldSlug}
                            item={item}
                          />
                        </Col>
                      </>
                    );
                  })}
                </Row>

                <hr />
              </>
            );
          } else if (field.fieldType === "dropdown") {
            return (
              <>
                <this.MandatoryField
                  isMandatoryNot={field.isMandatoryNot}
                  value={this.state.fieldValue[field.fieldSlug]}
                  name={field.fieldName}
                />
                <Col md={colSize}>
                  <label htmlFor="feInputState">
                    {" "}
                    {field.fieldName}{" "}
                    <strong style={{ color: "red" }}>
                      {" "}
                      {field.isMandatoryNot ? " " : "*"}
                    </strong>{" "}
                  </label>
                  :{" "}
                  <FormSelect
                    id="feInputState"
                    name={field.fieldSlug}
                    onChange={this.handleChange}
                  >
                    <option>
                      {this.state.fieldValue[field.fieldSlug]
                        ? this.state.fieldValue[field.fieldSlug]
                        : "Select"}
                    </option>
                    {field.checkBoxEnum.map(item => {
                      return (
                        <>
                          <option>{item}</option>
                        </>
                      );
                    })}
                    <option value="">Select</option>
                  </FormSelect>
                  <p>{field.notes}</p>
                </Col>
                <hr />
              </>
            );
          } else if (field.fieldType === "date") {
            let mm = this.state.fieldValue[`${field.fieldSlug}_mm`];
            let dd = this.state.fieldValue[`${field.fieldSlug}_dd`];
            return (
              <>
                <this.MandatoryField
                  isMandatoryNot={field.isMandatoryNot}
                  value={
                    this.state.fieldValue[field.fieldSlug + "_mm"] &&
                    this.state.fieldValue[field.fieldSlug + "_dd"]
                  }
                  name={field.fieldName}
                  type="date"
                />
                <Col md={radioColSize + 2}>
                  <label htmlFor="date">
                    {" "}
                    {field.fieldName}{" "}
                    <strong style={{ color: "red" }}>
                      {" "}
                      {field.isMandatoryNot ? " " : "*"}
                    </strong>{" "}
                  </label>
                  &nbsp;:&nbsp; &nbsp; &nbsp; &nbsp;
                  <this.customDate
                    name={field.fieldSlug}
                    // date={this.state.fieldValue[field.fieldSlug]}
                    date={`${mm}-${dd}-2021`}
                  />
                </Col>
                <p>{field.notes}</p>
                <this.Validation
                  min={field.minValue}
                  max={field.maxValue}
                  value={this.state.fieldValue[field.fieldSlug]}
                  name={field.fieldName}
                />{" "}
                <hr />
              </>
            );
          }

          else if (field.fieldType === "time") {
            return (
              <>
                <this.MandatoryField
                  isMandatoryNot={field.isMandatoryNot}
                  type="time"
                  value={
                    this.state.fieldValue[field.fieldSlug + "HH"] &&
                    this.state.fieldValue[field.fieldSlug + "MM"]
                  }
                  name={field.fieldName}
                />{" "}
                <Col md={12} sm="12">
                  <Row>
                    <Col lg="3" sm="12">
                      <InputGroupText
                        style={{
                          color: "#99a",
                          "font-size": "14px",
                          "font-weight": "500"
                        }}
                      >
                        {field.fieldName}{" "}
                        <strong style={{ color: "red" }}>
                          {" "}
                          {field.isMandatoryNot ? " " : "*"}
                        </strong>
                      </InputGroupText>
                    </Col>
                    <Col lg="2" sm="12">
                      <FormInput
                        type="number"
                        name={field.fieldSlug + "HH"}
                        onChange={this.handleChange}
                        value={this.state.fieldValue[field.fieldSlug + "HH"]}
                        min={field.minValue}
                        placeholder="Hour"
                        max={field.maxValue}
                        maxLength="2"
                        onInput={this.maxLengthCheck}
                      ></FormInput>
                      {/* &nbsp;&nbsp;&nbsp; : &nbsp;&nbsp;&nbsp; */}
                    </Col>
                    <Col lg="2" sm="12">
                      <FormInput
                        type="number"
                        name={field.fieldSlug + "MM"}
                        onChange={this.handleChange}
                        placeholder="Minute"
                        value={this.state.fieldValue[field.fieldSlug + "MM"]}
                        min={field.minValue}
                        max={field.maxValue}
                        maxLength="2"
                        onInput={this.maxLengthCheck}
                      ></FormInput>
                    </Col>
                  </Row>
                  <this.Validation
                    min={field.minValue}
                    max={field.maxValue}
                    value={this.state.fieldValue[field.fieldSlug + "HH"]}
                    name={field.fieldName}
                    type="HH"
                  />{" "}
                  <this.Validation
                    min={field.minValue}
                    max={field.maxValue}
                    value={this.state.fieldValue[field.fieldSlug + "MM"]}
                    name={field.fieldName}
                    type="MM"
                  />{" "}
                  <p>Note : Please type in clock time</p>
                  <p>{field.notes}</p>
                </Col>
                <hr />
              </>
            );
          } else if (field.fieldType === "title") {
            return (
              <>
                <Col md="12">
                  <h2> {field.fieldName} </h2>
                  <p>{field.notes}</p>
                  <hr />
                </Col>
              </>
            );
          } else if (field.fieldType === "sub_title") {
            return (
              <>
                <Col md={colSize}>
                  <h3>
                    {" "}
                    <strong> {field.fieldName} </strong>{" "}
                  </h3>
                  <p>{field.notes}</p>
                  <hr />
                </Col>
              </>
            );
          } else if (field.fieldType === "note") {
            return (
              <>
                <Col md="12">
                  {" "}
                  <strong style={{ fontSize: "20px", fontWeight: "700" }}>
                    {" "}
                    {field.fieldName}{" "}
                  </strong>{" "}
                  <hr />
                </Col>
              </>
            );
          } else if (
            field.fieldType === "image" ||
            field.fieldType === "file"
          ) {
            return (
              <>
                {field.fieldName} &nbsp;&nbsp; : &nbsp;&nbsp;
                <hr />
              </>
            );
          } else if (field.fieldType === "table") {
            return <div></div>;
          } else if (field.fieldType === "document") {
            return <div></div>;
          } else if (field.fieldType === "check_box") {
            return (
              <>
                <this.MandatoryField
                  isMandatoryNot={field.isMandatoryNot}
                  value={this.state.fieldValue[field.fieldSlug]}
                  name={field.fieldName}
                  type="check_box"
                  value2={this.state[field.fieldSlug]}
                />
                <Row>
                  <Col
                    md={12}
                    style={{
                      fontSize: "18px",
                      marginBottom: "20px"
                    }}
                  >
                    {" "}
                    {field.fieldName}{" "}
                    <strong style={{ color: "red" }}>
                      {" "}
                      {field.isMandatoryNot ? " " : "*"}
                    </strong>{" "}
                  </Col>

                  {field.checkBoxEnum.map((item, key) => {
                    let arr = [];
                    let name = field.fieldSlug;
                    if (this.state[name]) {
                      arr = this.state[name];
                    } else if (this.state.fieldValue[name]) {
                      arr = this.state.fieldValue[name];
                    }
                    // console.log(name, item);
                    let isChecked = arr.includes(item);
                    let disabled = arr.includes("None of the above");

                    if (disabled) {
                      if (
                        arr.includes("None of the above") &&
                        item === "None of the above"
                      ) {
                        disabled = false;
                      }
                      // else if (
                      //   arr.includes("Unknown") &&
                      //   item === "Unknown"
                      // ) {
                      //   disabled = false;
                      // }
                    }
                    return (
                      <>
                        <Col md="6">
                          <input
                            type="checkbox"
                            id="checkbox1"
                            style={{
                              fontSize: "16px",
                              border: "0px",
                              width: "20px",
                              height: "20px"
                            }}
                            defaultChecked={isChecked}
                            name={name}
                            value={item}
                            onChange={this.handleCheck}
                            disabled={disabled}
                          />
                          &nbsp; &nbsp; &nbsp;
                          {item}
                        </Col>
                      </>
                    );
                  })}
                </Row>
                <p>{field.notes}</p>
                <hr />
              </>
            );
          } else if (field.fieldType === "button") {
            return (
              <Button
                outline
                theme="dark"
                className="mb-2 mr-1"
                onClick={this.toggle}
              >
                For BARC Bleeding Criteria
              </Button>
            );
          }
          //return <li key={field.fieldSlug}>{field.fieldType}</li>;
        })}

        <this.BottomButton />
        <br />
        <strong>
          Steps : {this.state.start + 1} / {this.state.sliceArray.length - 1}
        </strong>
        <div>
          <Modal open={this.state.open} size="lg" toggle={this.toggle}>
            <ModalHeader>
              <Button
                theme="danger"
                className="mb-2 mr-1"
                onClick={this.toggle}
              >
                Close
              </Button>
            </ModalHeader>
            <ModalBody>
              <img
                src="https://httpsimage.com/v2/26a4c714-7171-430f-bfd1-5fdb53ba6878.png"
                alt="critiria"
                width="140%"
              />
            </ModalBody>
          </Modal>
        </div>
      </div>
    );
  }

  AddNewPost = () => {
    return (
      <Container fluid className="main-content-container px-4 pb-4">
        {/* Page Header */}

        <Row noGutters className="page-header py-4">
          <PageTitle
            sm="4"
            title="Add Patients Data"
            subtitle=""
            className="text-sm-left"
          />
        </Row>
        {this.state.start === 0 ? (
          <Row>
            <Col>
              <div className="card mb-4 p-4">
                <stong>Note</stong>
                <ol>
                  <li>
                    Fields marked ( * ) are mandatory. Please make sure this
                    fields are filled; otherwise red warning band will be shown
                    and will not be able to proceed forward.
                  </li>
                  <li>
                    Yellow warning bands are shown either to recheck the data
                    entered or if the value is out of limit. If data entered is
                    found correct, you can proceed forward.
                  </li>
                  <li>
                    Please make sure the date(s) entered for each is correct.
                  </li>
                  <li>The Time Fields are in 24 Hour Clock format.</li>
                  <li>
                    After entering data in a page, make sure to click “ Save &
                    Next “ to save the data on that page and to proceed to the
                    next one or in order to continue later.
                  </li>
                </ol>
              </div>
            </Col>
          </Row>
        ) : (
          ""
        )}

        <Row>
          <this.TopTab />
          <Col lg="12">
            <this.RenderFields />
          </Col>
        </Row>
      </Container>
    );
  };

  componentDidMount() {
    this.getFormFields();
  }

  render() {
    if (this.state.isCompleted) {
      // return <Redirect to="../dashbord" />;
      return (
        <div>
          <Modal size="lg" open={true}>
            <ModalHeader>Data Entered Successfully</ModalHeader>
            <ModalBody>
              <strong>Note</strong>
              <ol>
                <li>
                  {" "}
                  You will have the Edit option only for 7 Days for the
                  submitted data.
                </li>
                <li>
                  To do the Follow Up after 30 Days, go to the List Patient and
                  click Follow Up for the particular Patient.
                </li>
              </ol>
              <Link to="/dashbord">Goto Dashboard</Link>
            </ModalBody>
          </Modal>
        </div>
      );
    }
    if (this.state.sliceArray.length < 1) {
      return (
        <div
          style={{
            height: "80vh",
            display: "flex",
            alignItems: "center",
            marginLeft: "40%"
          }}
        >
          <Row className="justify-content-md-center">
            <Col lg="2">
              <ReactLoading type={"spinningBubbles"} color={"#5af"} />
              {/* <h2>Loading...</h2> */}
            </Col>
          </Row>
        </div>
      );
    } else if (this.state.isLoading) {
      return (
        <div
          style={{
            height: "80vh",
            display: "flex",
            alignItems: "center",
            marginLeft: "40%"
          }}
        >
          <Row className="justify-content-md-center">
            <Col lg="2">
              <ReactLoading type={"spinningBubbles"} color={"#5af"} />
              {/* <h2>Loading...</h2> */}
            </Col>
          </Row>
        </div>
      );
    } else {
      return this.AddNewPost();
    }
  }
}
