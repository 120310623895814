import React, { Component } from "react";

import {
  Container,
  Row,
  Col,
  ListGroup,
  ListGroupItem,
  Form,
  FormInput,
  Alert,
  FormSelect,
  Button
} from "shards-react";

import PageTitle from "../components/common/PageTitle";
import Cookie from "js-cookie";
const axios = require("axios").default;
const URL = require("../ApiUrl");

export default class AddUsers extends Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.InputFields = this.InputFields.bind(this);

    this.sendData = this.sendData.bind(this);
    this.alert = this.alert.bind(this);
    this.ChapterList = this.ChapterList.bind(this);
    this.getChapterList = this.getChapterList.bind(this);

    this.state = {
      chapterList: []
    };
  }

  handleChange(e) {
    if (e.target.name === "role") {
      this.getChapterList();
    }
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  async sendData(e) {
    e.preventDefault();
    const token = Cookie.get("jwtToken") ? Cookie.get("jwtToken") : null;
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };

    let stateData = Object.assign({}, this.state);

    let response = await axios
      .post(`${URL.BASE_URL}/auth/signup`, stateData, config)
      .catch(function(error) {
        console.log(error);
      });
    if (response && response.data.statusCode === 200) {
      this.setState({
        status: true,
        name: " ",
        email: "",
        hospital: "",
        password: "",
        role: "",
        chapterSlNo: ""
      });
    } else if (response && response.data.statusCode !== 200) {
      this.setState({
        error: true,
        errorCode: response.data.msg
      });
    } else {
      this.setState({
        status: false
      });
    }
    setTimeout(() => {
      this.setState({
        status: false,
        error: false
      });
    }, 2000);

    console.log(response);
  }

  async getChapterList() {
    const token = Cookie.get("jwtToken") ? Cookie.get("jwtToken") : null;
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    let response = await axios
      .get(`${URL.BASE_URL}/auth/coordinators`, config)
      .catch(error => {
        console.log(error);
        this.setState({
          error: error
        });
      });
    if (response) {
      this.setState({
        chapterList: response.data.data
      });
      // console.log(response.data.data);

      //------
    }
  }

  alert() {
    if (this.state.status) {
      return (
        <Alert theme="success" className="mb-0">
          <i className="fa fa-info mx-2"></i> Successfully added fields
        </Alert>
      );
    } else if (this.state.error) {
      return (
        <Alert theme="danger" className="mb-0">
          <i className="fa fa-danger mx-2"></i> Error !! {this.state.errorCode}
        </Alert>
      );
    } else {
      return <div></div>;
    }
  }

  ChapterList() {
    let cList = this.state.chapterList;
    if (this.state.role === "sub_coordinator") {
      if (cList.length !== 0) {
        return (
          <div>
            <Row form>
              <Col md="12" className="form-group">
                <label htmlFor="feInputState">Chapter </label>

                <FormSelect
                  id="feInputState232"
                  name="chapterId"
                  onChange={this.handleChange}
                >
                  <option value=" "> Select </option>
                  {cList.map(item => {
                    return <option value={item._id}> {item.hospital} </option>;
                  })}
                </FormSelect>
              </Col>
            </Row>

            <Row form>
              <Col md="12" className="form-group">
                <label htmlFor="hosNo">Hospital No </label>
                <FormInput
                  id="hosNo"
                  type="Number"
                  placeholder="Hospital Id"
                  name="hospitalSlNo"
                  value={this.state.hospitalSlNo}
                  onChange={this.handleChange}
                />
              </Col>
            </Row>
          </div>
        );
      } else {
        return <div>Loading...</div>;
      }
    } else {
      return <div></div>;
    }
  }

  componentDidMount() {
    this.getChapterList();
  }

  InputFields() {
    return (
      <ListGroup flush>
        <ListGroupItem className="p-3">
          <Row>
            <Col>
              <Form>
                <Row form>
                  <Col md="12" className="form-group">
                    <label htmlFor="fieldName">User Name</label>
                    <FormInput
                      id="fieldName"
                      type="text"
                      placeholder="User Name"
                      name="name"
                      value={this.state.name}
                      onChange={this.handleChange}
                    />
                  </Col>
                </Row>

                <Row form>
                  <Col md="12" className="form-group">
                    <label htmlFor="feInputState">Email </label>

                    <FormInput
                      id="fieldEmail"
                      type="email"
                      placeholder="Email"
                      name="email"
                      value={this.state.email}
                      onChange={this.handleChange}
                    />
                  </Col>
                </Row>
                <Row form>
                  <Col md="12" className="form-group">
                    <label htmlFor="feInputState">Role </label>

                    <FormSelect
                      id="feInputState"
                      name="role"
                      onChange={this.handleChange}
                    >
                      <option value=" "> Select </option>
                      <option value="admin"> Admin </option>
                      <option value="coordinator">
                        {" "}
                        Chapter Co-ordinator{" "}
                      </option>
                      <option value="sub_coordinator"> Hospitals </option>
                    </FormSelect>
                  </Col>
                </Row>
                <this.ChapterList />
                <Row form>
                  <Col md="12" className="form-group">
                    <label htmlFor="feInputState">Hospital / Chapter</label>

                    <FormInput
                      id="fieldHosp"
                      type="text"
                      placeholder="Hospital name"
                      name="hospital"
                      onChange={this.handleChange}
                      value={this.state.hospital}
                    />
                  </Col>
                </Row>
                <Row form>
                  <Col md="12" className="form-group">
                    <label htmlFor="feInputState">Chapter ID </label>

                    <FormInput
                      id="fieldHosp"
                      type="Number"
                      max="99"
                      placeholder="Chapter ID"
                      name="chapterSlNo"
                      onChange={this.handleChange}
                      value={this.state.chapterSlNo}
                    />
                  </Col>
                </Row>
                <Row form>
                  <Col md="12" className="form-group">
                    <label htmlFor="feInputState">Password </label>

                    <FormInput
                      id="fieldPass"
                      type="password"
                      name="password"
                      placeholder="Password"
                      onChange={this.handleChange}
                      value={this.state.password}
                      required
                    />
                  </Col>
                </Row>
                <Button onClick={this.sendData}>Submit</Button>
              </Form>
            </Col>
          </Row>
        </ListGroupItem>
      </ListGroup>
    );
  }

  AddNewField = () => {
    return (
      <>
        <Container fluid className="main-content-container px-4 pb-4">
          {/* Page Header */}
          <Row noGutters className="page-header py-4">
            <PageTitle
              sm="4"
              title="Add Users"
              subtitle="Create Hospitals / Chapters"
              className="text-sm-left"
            />
          </Row>
          <Row>
            <Col md="6">
              <this.alert />
              <this.InputFields />
            </Col>
          </Row>
        </Container>
      </>
    );
  };

  render() {
    return this.AddNewField();
  }
}
