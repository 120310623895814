import React, { Component } from "react";
import { NavItem, NavLink } from "shards-react";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  CardBody,
  CardHeader,
  Card,
  Button,
  Modal,
  ModalBody,
  ModalHeader
} from "shards-react";
import PageTitle from "../components/common/PageTitle";
import Cookie from "js-cookie";
const axios = require("axios").default;
const URL = require("../ApiUrl");

export default class ListFormData extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.getData = this.getData.bind(this);
    this.state = {
      limit: 20,
      page: 1,
      fieldData: [],
      open: false,
      nameAndId: {
        fields: {}
      }
    };
  }

  async getData() {
    const token = Cookie.get("jwtToken") ? Cookie.get("jwtToken") : null;

    let response = await axios({
      method: "get",
      url: `${URL.BASE_URL}/fields/get`,

      headers: { Authorization: `Bearer ${token}` }
    });
    console.log(response);

    this.setState({
      fieldData: response.data.data,
      token: token
    });
    console.log(this.state.fieldData);
  }

  toggle() {
    this.setState({
      open: !this.state.open
    });
  }

  ModalPopUp(data) {
    this.setState({
      open: !this.state.open,
      nameAndId: data
    });
    console.log(data);
  }

  deletePatient = async id => {
    let token = this.state.token;
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    let response = await axios
      .delete(`${URL.BASE_URL}/forms/delete/${id}`, config)
      .catch(function(error) {
        console.log(error);
      });
    if (response.data.statusCode === 200) {
      this.getData();
      this.toggle();
    }
  };

  RenderTable = () => {
    return (
      <Card small className="mb-4">
        <CardHeader className="border-bottom">
          <h6 className="m-0">Active Users</h6>
        </CardHeader>
        <CardBody className="p-0 pb-3">
          <table className="table mb-0">
            <thead className="bg-light">
              <tr>
                <th scope="col" className="border-0">
                  Position
                </th>
                <th scope="col" className="border-0">
                  Name
                </th>
                <th scope="col" className="border-0">
                  Parent Id
                </th>
                <th scope="col" className="border-0">
                  Type
                </th>
                <th scope="col" className="border-0">
                  Options
                </th>
              </tr>
            </thead>
            <tbody>
              {this.state.fieldData.map((data, no) => {
                return (
                  <tr>
                    <td>{data.pos}</td>
                    <td>{data.fieldName}</td>
                    <td>{data.parent}</td>
                    <td>{data.fieldType}</td>

                    <td>
                      <Link to={`editfields/${data._id}`}>
                        <Button theme="success" className="mb-2 mr-1">
                          Edit <i class="fas fa-edit"></i>
                        </Button>
                      </Link>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </CardBody>
      </Card>
    );
  };

  AddNewPost = () => {
    return (
      <Container fluid className="main-content-container px-4 pb-4">
        {/* Page Header */}
        <Row noGutters className="page-header py-4">
          <PageTitle
            sm="4"
            title="List Form"
            subtitle="List Form Fields"
            className="text-sm-left"
          />
        </Row>
        <Row>
          <Col md="12">
            <this.RenderTable />
          </Col>
        </Row>
      </Container>
    );
  };

  componentDidMount() {
    this.getData();
    console.log(this.props.match.params.id);
  }

  render() {
    return this.AddNewPost();
  }
}
