import React from "react";

import { Redirect } from "react-router-dom";

import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Collapse,
  NavItem,
  NavLink
} from "shards-react";
import Cookie from "js-cookie";

export default class UserActions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      userData: "",
      auth: true
    };

    this.toggleUserActions = this.toggleUserActions.bind(this);
    this.getToken = this.getToken.bind(this);
    this.logout = this.logout.bind(this);
  }

  getToken = () => {
    //const token = Cookie.get("jwtToken") ? Cookie.get("jwtToken") : null;
    const userData = Cookie.get("userData") ? Cookie.get("userData") : null;

    this.setState({
      userData: JSON.parse(userData)
    });
  };

  componentDidMount() {
    this.getToken();
  }

  toggleUserActions() {
    this.setState({
      visible: !this.state.visible
    });
  }

  logout() {
    //console.log(this.state);
    Cookie.remove("userData");
    Cookie.remove("jwtToken");
    this.setState({
      auth: false
    });
  }

  render() {
    if (!this.state.auth) {
      return <Redirect to="/login" />;
    }
    return (
      <NavItem tag={Dropdown} caret toggle={this.toggleUserActions}>
        <DropdownToggle caret tag={NavLink} className="text-nowrap px-3">
          <img
            className="user-avatar rounded-circle mr-2"
            src="https://i1.wp.com/www.winhelponline.com/blog/wp-content/uploads/2017/12/user.png?fit=256%2C256&quality=100"
            alt="User Avatar"
          />{" "}
          <span className="d-none d-md-inline-block">
            {this.state.userData.name}
          </span>
        </DropdownToggle>
        <Collapse tag={DropdownMenu} right small open={this.state.visible}>
          <DropdownItem divider />
          <a href="/logout">
            <DropdownItem className="text-danger">
              <i className="material-icons text-danger">&#xE879;</i>
              <strong>Logout</strong>
            </DropdownItem>
          </a>
        </Collapse>
      </NavItem>
    );
  }
}
