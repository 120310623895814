import React from "react";
import { Redirect } from "react-router-dom";

// Layout Types
import { DefaultLayout, LoginLayout } from "./layouts";

// Route Views
import Dashbord from "./views/Dashbord";
import UserProfileLite from "./views/UserProfileLite";
import AddFormData from "./views/AddFormData";
import Errors from "./views/Errors";
import ComponentsOverview from "./views/ComponentsOverview";
import Tables from "./views/Tables";
import AddFields from "./views/AddFields";
import AddFollowUp from "./views/AddFollowUp";
import ListDatas from "./views/listFormData";
import Finished from "./views/FinishedData.js";
import AddUser from "./views/AddUser";
import ListUser from "./views/listUsers";
import UserData from "./views/UserData";
import AdminData from "./views/AdminData";
import Logout from "./views/Logout";
import Incomplet from "./views/listIncomp";
import Fields from "./views/listInFields";
import FieldEdit from "./views/EditForm";
import FollowUpData from "./views/AddFollowUpData1";
import FollowUpEdit from "./views/followUpEdit";
import Report from "./views/Report";
import Demo from "./views/Demo";

export default [
  {
    path: "/",
    exact: true,
    layout: DefaultLayout,
    component: () => <Redirect to="/dashbord" />
  },
  {
    path: "/dashbord",
    layout: DefaultLayout,
    component: Dashbord
  },
  {
    path: "/user-profile-lite",
    layout: DefaultLayout,
    component: UserProfileLite
  },
  {
    path: "/add-formdata",
    layout: DefaultLayout,
    component: AddFormData
  },
  {
    path: "/errors",
    layout: DefaultLayout,
    component: Errors
  },
  {
    path: "/components-overview",
    layout: DefaultLayout,
    component: ComponentsOverview
  },
  {
    path: "/tables",
    layout: DefaultLayout,
    component: Tables
  },
  {
    path: "/add-fields",
    layout: DefaultLayout,
    component: AddFields
  },
  {
    path: "/add-followup",
    layout: DefaultLayout,
    component: AddFollowUp
  },
  {
    path: "/add-followdata/:id/:id2/:no",
    layout: DefaultLayout,
    component: FollowUpData
  },
  {
    path: "/edit-followdata/:id/:no",
    layout: DefaultLayout,
    component: FollowUpEdit
  },
  {
    path: "/list-formdata",
    layout: DefaultLayout,
    component: ListDatas
  },
  {
    path: "/incomplete",
    layout: DefaultLayout,
    component: Incomplet
  },
  {
    path: "/finished",
    layout: DefaultLayout,
    component: Finished
  },
  {
    path: "/patientdata/:id",
    layout: DefaultLayout,
    component: AddFormData
  },
  {
    path: "/add-user",
    layout: DefaultLayout,
    component: AddUser
  },
  {
    path: "/list-user",
    layout: DefaultLayout,
    component: ListUser
  },
  {
    path: "/userdata/:id",
    layout: DefaultLayout,
    component: UserData
  },
  {
    path: "/listfields",
    layout: DefaultLayout,
    component: Fields
  },
  {
    path: "/editfields/:id",
    layout: DefaultLayout,
    component: FieldEdit
  },
  {
    path: "/admin-profile",
    layout: DefaultLayout,
    component: AdminData
  },
  {
    path: "/report",
    layout: DefaultLayout,
    component: Report
  },
  {
    path: "/demo",
    layout: DefaultLayout,
    component: Demo
  },
  {
    path: "/logout",
    layout: LoginLayout,
    component: Logout
  },

  {
    path: "/login",
    layout: LoginLayout,
    component: () => <Redirect to="/dashbord" />
  }
];
