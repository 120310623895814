import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "shards-react";
import DashbordCard from "../components/clean/dashbordCard";
import BackgroundImg from "../assets/blue-gradient-background.png";

const titleStyle = {
  backgroundImage: `url(${BackgroundImg})`,
  // background: "rgb(0,177,255)",
  // background:
  //  "linear-gradient(48deg, rgba(0,177,255,1) 0%, rgba(163,224,255,1) 100%)",
  fontWeight: "700",
  fontSize: "1.5rem",
  color: "#000"
};
const Dashbord = ({ userRole }) => (
  <Container fluid className="main-content-container px-4 mb-4">
    {/* Page Header */}
    <Row noGutters className="page-header py-1"></Row>
    {/* Small Stats Blocks */}
    <Row>
      <Col>
        <div className="text-center m-4 pt-3 card" style={titleStyle}>
          <p>
            Prospective - REgistry of STEMI management in COvid era - INDIA
            (PRESCO-INDIA){" "}
          </p>
        </div>
      </Col>
    </Row>

    <DashbordCard userRole={userRole} />
  </Container>
);

export default Dashbord;
