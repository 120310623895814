import React, { Component } from "react";

import { Row, Col, FormSelect, Button } from "shards-react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import "./css/dashbordCard.css";
import UsersByDevice from "../blog/UsersByDevice";
import Cookie from "js-cookie";
import ReactLoading from "react-loading";
import BackgroundImg from "../../assets/blue-gradient-background.png";
import Select from "react-select";

const rawData = Cookie.get("userData") ? Cookie.get("userData") : null;
const userData = JSON.parse(rawData);
const axios = require("axios").default;
const URL = require("../../ApiUrl");

export default class dashbordCard extends Component {
  constructor(props) {
    super(props);
    this.getBasicSts = this.getBasicSts.bind(this);
    this.BasicCards = this.BasicCards.bind(this);

    this.state = {
      isLoaded: false,
      chapterName: "Select Center",
      basic_status: {
        comp: 0,
        incomp: 0,
        patient_count: 0,
        total_count: 0,
        withFollowUp: 0,
        maleCount: 0,
        malePercentage: 0,
        femaleCount: 0,
        femalePercentage: 0,
        covidPosCount: 8,
        covidPosPercentage: 0,
        covidNegCount: 0,
        covidNegPercentage: 0,
        throCount: 0,
        throPercentage: 0,
        ppciCount: 0,
        ppciPercentage: 0,
        nrtCount: 0,
        nrtPercentage: 0,
        mortality: 0,
        pharmaco: 0,
        hosp_data: [{ _id: "", name: "" }],
        firstSub: 0
      }
    };
  }

  async getBasicSts(id) {
    // console.log("🚀💛💛", id);

    this.setState({ isLoaded: false });

    const token = Cookie.get("jwtToken") ? Cookie.get("jwtToken") : null;
    let response2;
    if (id === "all") {
      response2 = await axios({
        method: "get",
        url: `${URL.BASE_URL}/forms/basic`,

        headers: { Authorization: `Bearer ${token}` }
      });
      this.setState({ chapterName: "Select Center" });
    } else {
      const myArr = id.split("|");
      response2 = await axios({
        method: "get",
        url: `${URL.BASE_URL}/forms/basic?centerId=${myArr[0]}`,

        headers: { Authorization: `Bearer ${token}` }
      });
      this.setState({ chapterName: myArr[1] });
    }

    if (response2.data.statusCode === 200) {
      this.setState({ basic_status: response2.data.data, isLoaded: true });
    }

    // console.log("💛💛💛", response2.data.data);
    //console.log(response.data.data);
  }

  componentDidMount() {
    this.getBasicSts("all");
  }

  printDocument = () => {
    const input = document.getElementById("divToPrint");
    html2canvas(input).then(canvas => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("landscape");
      pdf.addImage(imgData, "JPEG", 50, 0, 200, 200);
      // pdf.output('dataurlnewwindow');
      pdf.save("download.pdf");
    });
  };

  CenterSelect = () => {
    let options = [{ value: "all", label: "All" }];
    this.state.basic_status.hosp_data.map(item =>
      options.push({
        value: `${item._id}|${item.hospital}`,
        label: item.hospital
      })
    );
    return (
      <div>
        <Row form>
          <Col md="1" className="mt-1 ml-1">
            <strong htmlFor="feInputState">Center </strong>
          </Col>
          <Col md="8" className="form-group">
            <Select
              aria-labelledby="aria-label"
              inputId="aria-example-input"
              name="aria-live-color"
              options={options}
              onChange={e => this.getBasicSts(e.value)}
              defaultValue={{ value: "", label: this.state.chapterName }}
              isSearchable
            />
          </Col>
          <Col md="2" className=" ml-4">
            <Button onClick={this.printDocument} theme="secondary">
              Save <i className="material-icons">description</i>
            </Button>
          </Col>
        </Row>
      </div>
    );
  };
  BasicCards() {
    // console.log(userData.role, "🎇💛✅🚀🚩");

    return (
      <div>
        <Row>
          <Col md="4" lg="4">
            <div className="cards card1">
              <div className="subtitle"> No. of STEMI Patient</div>
              <div className="title">{this.state.basic_status.total_count}</div>
            </div>
          </Col>
          <Col md="4" lg="4">
            <div className="cards card1">
              <div className="subtitle"> 1st Submissions</div>
              <div className="title">{this.state.basic_status.firstSub}</div>
            </div>
          </Col>
          <Col lg="4" md="4">
            <div className="cards card1">
              <div className="subtitle">
                Patients Data <br></br> Final w/ Follow Up{" "}
              </div>
              <div className="title">
                {this.state.basic_status.withFollowUp}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }

  PieGraphs = () => {
    let GenderData = {
      datasets: [
        {
          hoverBorderColor: "#ffffff",
          data: [
            this.state.basic_status.pharmaco,
            this.state.basic_status.total_count -
              this.state.basic_status.pharmaco
          ],
          backgroundColor: ["#7d5fff", "#18dcff"]
        }
      ],
      labels: ["Yes", "No"]
    };

    let GenderFooter = [
      {
        title: "Yes",
        count: this.state.basic_status.pharmaco,
        percentage: parseFloat(
          (this.state.basic_status.pharmaco * 100) /
            this.state.basic_status.total_count
        ).toFixed(2)
      },
      {
        title: "No",
        count:
          this.state.basic_status.total_count -
          this.state.basic_status.pharmaco,
        percentage: parseFloat(
          ((this.state.basic_status.total_count -
            this.state.basic_status.pharmaco) *
            100) /
            this.state.basic_status.total_count
        ).toFixed(2)
      }
    ];

    let CovidData = {
      datasets: [
        {
          hoverBorderColor: "#ffffff",
          data: [
            this.state.basic_status.covidPosCount,
            this.state.basic_status.covidNegCount,
            this.state.basic_status.covidNotDoneCount
          ],
          backgroundColor: ["#18dcff", "#c56cf0", "#5f27cd"]
        }
      ],
      labels: ["Covid Positive", "Covid Negative", "Not Tested"]
    };

    let CovidFooter = [
      {
        title: " Positive",
        count: this.state.basic_status.covidPosCount,
        percentage: this.state.basic_status.covidPosPercentage
      },
      {
        title: " Negative",
        count: this.state.basic_status.covidNegCount,
        percentage: this.state.basic_status.covidNegPercentage
      },
      {
        title: " Not Tested",
        count: this.state.basic_status.covidNotDoneCount,
        percentage: this.state.basic_status.covidNotDonePercentage
      }
    ];

    let PropData = {
      datasets: [
        {
          hoverBorderColor: "#ffffff",
          data: [
            this.state.basic_status.throCount,
            this.state.basic_status.ppciCount,
            this.state.basic_status.nrtCount
          ],
          backgroundColor: ["#7300ff", "#df03ee", "#18dcff"]
        }
      ],
      labels: ["Thrombolyis", "Primary PCI ", "No Reperfusion "]
    };

    let PropFooter = [
      {
        title: " Thrombolyis",
        count: this.state.basic_status.throCount,
        percentage: this.state.basic_status.throPercentage
      },
      {
        title: " Primary PCI",
        count: this.state.basic_status.ppciCount,
        percentage: this.state.basic_status.ppciPercentage
      },
      {
        title: "No Reperfusion",
        count: this.state.basic_status.nrtCount,
        percentage: this.state.basic_status.nrtPercentage
      }
    ];

    return (
      <div>
        <Row className="mt-4">
          <Col lg="4">
            <UsersByDevice
              chartData={GenderData}
              title="Pharmaco Invasive"
              footer={GenderFooter}
            />
          </Col>

          <Col lg="4">
            <UsersByDevice
              chartData={CovidData}
              title="COVID positive"
              footer={CovidFooter}
            />
          </Col>

          <Col lg="4">
            <UsersByDevice
              chartData={PropData}
              title="Reperfusion Therapy "
              footer={PropFooter}
            />
          </Col>
        </Row>
      </div>
    );
  };
  SecondRow = () => {
    let data = {
      datasets: [
        {
          label: "Mean Time",
          hoverBorderColor: "#ffffff",
          data: [68, 32, 52, 61],
          backgroundColor: ["#c56cf0", "#7158e2", "#17c0eb", "#67e6dc"]
        }
      ],
      labels: ["Male", "Female", "wewe", "fdfdf"]
    };
    let footer = [
      { title: "Male", count: 123 },
      { title: "Female", count: 48 }
    ];
    return (
      <div>
        <Row className="mt-4">
          {/* <Col md="8">
            <UsersByDevice
              chartData={data}
              title="Proportion of Thrombolyis "
              footer={footer}
              type="bar"
              height="150"
            />
          </Col> */}

          <Col md="4">
            <div className="cards card2">
              <div className="subtitle">
                Female - {this.state.basic_status.femaleCount}
              </div>
              <div className="title">
                {this.state.basic_status.femaleCount > 0
                  ? parseFloat(
                      (this.state.basic_status.femaleCount * 100) /
                        this.state.basic_status.total_count
                    ).toFixed(2)
                  : "0"}
                &nbsp;%
              </div>
            </div>
          </Col>

          <Col md="4">
            <div className="cards card2">
              <div className="subtitle">
                Male - {this.state.basic_status.maleCount}
              </div>
              <div className="title">
                {this.state.basic_status.maleCount > 0
                  ? parseFloat(
                      (this.state.basic_status.maleCount * 100) /
                        this.state.basic_status.total_count
                    ).toFixed(2)
                  : "0"}
                &nbsp;%
              </div>
            </div>
          </Col>

          <Col md="4">
            <div className="cards card2">
              <div className="subtitle">
                In hospital mortality - {this.state.basic_status.mortality}
              </div>

              <div className="title">
                {this.state.basic_status.mortality > 0
                  ? parseFloat(
                      (this.state.basic_status.mortality * 100) /
                        this.state.basic_status.total_count
                    ).toFixed(2)
                  : "0"}
                &nbsp;%
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  };

  render() {
    const titleStyle = {
      backgroundImage: `url(${BackgroundImg})`,
      // background: "rgb(0,177,255)",
      //   background:
      //      "linear-gradient(48deg, rgba(0,177,255,1) 0%, rgba(163,224,255,1) 100%)",
      fontWeight: "700",
      fontSize: "1.5rem",
      color: "#000"
    };
    if (this.state.isLoaded) {
      return (
        <div>
          {userData.role === "sub_coordinator" ? (
            <>
              <Row>
                <Col>
                  <div
                    className="text-center mx-4 pt-3 card"
                    style={titleStyle}
                  >
                    <p>{userData.name}</p>
                  </div>
                </Col>
              </Row>
            </>
          ) : (
            ""
          )}
          {userData.role === "super_admin" ||
          userData.role === "admin" ||
          userData.role === "coordinator" ? (
            <>
              <this.CenterSelect />
            </>
          ) : (
            ""
          )}
          <div id="divToPrint">
            <this.BasicCards />
            {/* {userData.role === "super_admin" ||
            userData.role === "admin" ||
            userData.role === "coordinator" ? ( */}
            <div>
              <this.SecondRow />

              <this.PieGraphs />
            </div>
            {/* ) : (
               ""
             )} */}
          </div>
        </div>
      );
    } else {
      return (
        <div
          style={{
            height: "80vh",
            display: "flex",
            alignItems: "center",
            marginLeft: "40%"
          }}
        >
          <Row className="justify-content-md-center">
            <Col lg="2">
              <ReactLoading type={"spinningBubbles"} color={"#7158e2"} />
              {/* <h2>Loading...</h2> */}
            </Col>
          </Row>
        </div>
      );
    }
  }
}
